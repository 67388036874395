:root {
  --primary: #102250;
  --secondary: #A6CE38;
  --white: #fff;
  --black: #000;
  --text: #2d2b3e;
  --dark: #030303;
  --lightbg: #F2F4F2;
  --border: #D6D9DC;
  --primary-active: #18347C;
  --primary-checked: #777d8f;
  --primary-disabled: #727fa1;

  --primary-light: #FFFFFF;
  --secondary-dark: #4C6700;
  --secondary-light: #102250;
  --success: #7DBB00;
  
  --page-title-font-color: var(--primary);
  --page-title-font-size: 14px;
  --content-title-color: #2d2b3e;
  --content-title-font: 13px;
  --content-title-font-weight: bold;
  --content-color: #2d2b3e;
  --content-size: 13px;
  --button-size: 14px;
  --button-bg-color: var(--primary);
  --button-text-color: #FFFFFF;
  --button-hover-bg-color: #FFFFFF;
  --button-hover-text-color: var(--primary);


  --gray1: #919191;
  --gray2: #9D9D9D;
  --gray3: #808080;
  --gray4: #D5D5D5;
  --gray5: #EAEAEA;
  --gray7:#606060;
  --gray8: #e8e8e8;



  --blue: #147AD6;
  --blue-light: #79D2DE;
  --pink: #EC6666;
  --purple: #AF8BEB;
  --yellow: #E9D96D;
  --orange: #FCBB4B;



  --h1: 72px;

  --font60: 60px;
  --font25: 25px;
  --font24: 24px;
  --font22: 22px;
  --font20: 20px;
  --font18: 18px;
  --font17: 17px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;

  --thin: 100;
  --extralight: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
  --black900: 900;

  --body-font: 'Barlow';

  // --tipLeft : 0px;
  // --tipTop: 0px;
}
[class*="col-"] {
  padding: 0px 5px;
}
.col{
  padding: 0px 5px;
}
p {
  font-size: var(--font13);
}

.loader {
  position: absolute;
  width: 100%;

  height: 100%;
  background: #f0f8ffbd;
  display: none;
  z-index: 30;
}

.loader img {
  width: 25%;
  align-self: center;
  position: absolute;
  left: 35%;
}

.graphblock.active .loader {
  display: grid;
}

.dashcol a {
  height: 100%;
}

.status_icon {
  width: 30px;
}

.table_icon {
  text-align: center;
}

.action_icon {
  width: 30px;
}

.pending_icon {
  width: 30px;
}

.full_width {
  width: 100%;
}

.m-0 {
  margin: 0;
}

.m-30-0 {
  margin: 30px 0px !important;
}
.LicenseMan{
flex: 0 0 auto;
width: 26.666667%;
text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #f6a821;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.box-shade {
  opacity: 0.2;
  cursor: default;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#updatehead {
  display: none;
}

.upload_btn {
  background-color: #b1bd8b !important;
}

.upload_example {
  padding: 0.375rem 0.75rem;
}
.upload_file {
  padding: 0.375rem 0.75rem;
  padding-left: initial;
}

.upload_example a {
  color: var(--primary) !important;
}

.detail_icon {
  width: 20px;
}

input[disabled] {
  background-color: #e9ecef !important;
  opacity: 1;
}

select[disabled] {
  background-color: #e9ecef !important;
  opacity: 1;
}

.back-color {
  background-color: #414141 !important;
  border-radius: 3px;
  margin-left: 0.6em;
  margin-bottom: 0.6em;
  height: 45px;
  border: 1px solid;
  width: 130px;
  float: left;
  display: flex;
  align-items: center;
}

.plato-img {
  height: 29px;
  margin: 0 auto;
}

.delete_info_tools {
  width: 100%;
  height: 50px;
}

textarea {
  min-height: 6em;
    max-height: 12vw;
    width: 100%;
    background: #f2f4f2;
    resize: none;
    font-size: var(--font13);
    padding: 16px;
    border: none;
    border-radius: 0px;
    min-width: 100px;
}

.internal_label {
  color: #030303;
  font-size: 16px;
}

#licenceStr {
  border: 2px solid var(--primary);
  margin-top: 10px;
}

#licenceForm {
  margin-top: 10px;
}

#licenceForm .btnwrap {
  margin-top: 10px;
}

.alert {
  width: 100%;
  max-width: 500px;
  position: fixed;
  right: 0;
  margin: 0 auto;
  top: 15px;
  left: 0;
  z-index: 999999;
  display: flex;
  border-left: 5px solid;
  animation: popup 0.7s;
  // transition: all 5s ease-in-out;
}

.alert-success {
  color: #0f5132;
  background-color: #f9fff8;
  border-color: #0f5132;
}

.alert-warning {
  color: #664d03;
  background-color: #fffdf5;
  border-color: #664d03
}

.alert-error {
  color: #842029;
  background-color: #f6f5f5;
  border-color: #842029
}

.alert_img {
  flex-basis: 10%;
  padding: 0;
  display: grid;
}

.alert_msg_contain {
  flex-basis: 80%;
  padding: 0;
  word-break: break-all;
}

.alert strong {
  align-self: center;
}

.alert .btn-close {
  display: none;
}

.rbt-token {
  background-color: var(--primary) !important;
  color: #ffffff !important;
}

.rbt-input-multi.focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
  color: var(--primary);
  font-weight: bolder;
  font-size: 20px;
  margin: 2px;
  margin-left: 20px;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.ms-options-wrap>.ms-options {
  min-height: auto !important;
  max-height: fit-content !important;
}

.align-radio {
  text-align: center;
}

#group_tr th {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.num-box.disabled {
  pointer-events: none;
}

.txt-upper {
  text-transform: capitalize;
}

.mr-35 {
  margin-right: 35px;
}
.mr-23 {
  margin-right: 22px;
}
.mr-5 {
  margin-right: 5px !important;
}
.w-35 {
  width: 35%;
}

.inlist {
  width: 110%;
  word-break: break-word;
  padding-right: 20px;
}

.accordion-body .intype {
  width: 145%;
}

.fw-9 {
  flex-basis: 8%;
  word-break: break-word;
}

.fw-17 {
  flex-basis: 17%;
  word-break: break-word;
}

.fw-20 {
  flex-basis: 20%;
  word-break: break-word;
  margin: 0 !important;
}

.fw-25 {
  flex-basis: 25%;
  word-break: break-word;
  margin: 0 !important;
}

.fw-26 {
  flex-basis: 26%;
}

.fw-30 {
  flex-basis: 30%;
}

.fw-60 {
  flex-basis: 60%;
  word-break: break-word;
}

.fw-80 {
  flex-basis: 80%;
  word-break: break-word;
}

.graph-des-nav ul li {
  padding-right: 6px !important;
}

.flowbox h5 {
  font-size: 25px;
}

.flowacti-block .active h4,
.flowacti-block .active .flowlabel,
.flowacti-block .active .flownum,
.flowacti-block .active .numlabel,
.flowacti-block .active .numdes,
.flowacti-block .active h2,
.flowacti-block .active h5 {
  color: var(--white) !important;
}
.flowacti-block .active .flownum-ftr {
  background-color: rgba(3, 3, 3, 0.12);
}

.axis_legend {
  transform: rotate(270deg);
  position: absolute;
  left: -40px;
  bottom: 42%;
  font-size: 12px;
}

// .axis_legend {
//   transform: rotate(270deg);
//   position: relative;
//   right: 160px;
//   bottom: 28%;
//   font-size: 12px;
// }
.axis_legend_2 {
  transform: rotate(270deg);
  position: absolute;
  left: -48px;
  bottom: 50%;
  font-size: 12px;
}

.x_axis_legend {
  font-size: 12px;
}

.graph-des-nav li span.dark-orange {
  background-color: #ff6f00;
}

#dark-black {
  background: #000 !important;
}

.stories-list h5 {
  text-transform: capitalize;
}

.flownum {
  font-size: 28px !important;
}

.flownum-ftr .numdes {
  font-size: 14px !important;
   
  font-weight: var(--regular);
}

.accordion-header {
  width: 100%;
}

.dd_picker .bootstrap-select>.dropdown-toggle {
  border: none !important;
  font-weight: bold;
  font-size: 16px;
}

.dd_picker.frmgroup {
  width: 20%;
  padding: 0;
}

.Drill_Sprint option {
  display: none;
}

.donut .labels {
  font-size: 10px !important;
}

.avg_day_summary {
  padding: 0px 4px 0px 4px;
  font-size: 20px;
}

.avg_lable {
  float: right;
}

#getApplication {
  display: none;
}

.flowbox.disabled {
  pointer-events: none;
  border: 1px solid #fff;
}

// .summary_header{
//   display: flex;
// }
.fw-5 {
  flex-basis: 4.5%;
}

.fw-9 {
  flex-basis: 9%;
}

.fw-10 {
  flex-basis: 10%;
}

.fw-37 {
  flex-basis: 37%;
  word-break: break-word;
}

.fw-19 {
  flex-basis: 19.5%;
}

.fw-10-wide {
  flex-basis: 10%;
  word-break: break-word;
}

.fw-20 {
  flex-basis: 20%;
}

.fw1-20 {
  flex-basis: 22%;
}

.fw-15 {
  flex-basis: 15%;
}

.fw-90 {
  flex-basis: 90%
}

.fw-50 {
  flex-basis: 50%;
}

.fw-60 {
  flex-basis: 55%;
  word-break: break-word;
}

.fw-65 {
  flex-basis: 65%;
  word-wrap: break-word;
}

.fw-62 {
  flex-basis: 62%;
  word-wrap: break-word;
}

.fw-40 {
  flex-basis: 40%;
}

.fw-70 {
  flex-basis: 70%;
  word-break: break-word;
}

.fw-35 {
  flex-basis: 35%;
}

.fw-33 {
  flex-basis: 33%;
}

.w-5p {
  width: 5%;
}

.w-10p {
  width: 10%;
}

.w-15p {
  width: 15%;
}

.w-20p {
  width: 20%;
}
.w-23p{
  width: 23%;
}
.w-25p {
  width: 25%;
}

.w-30p {
  width: 30%;
}

.w-40p {
  width: 40%;
}

.w-50p {
  width: 50%;
}

.w-60p {
  width: 60%;
}

.w-70p {
  width: 70%;
}

.w-75p {
  width: 75%;
}

.center {
  display: flex;
  justify-content: center;
}

.pr-20 {
  padding-right: 20px;
}

.pl-5 {
  padding-left: 6px;
}

.pr-7 {
  padding-right: 7%;
}

.pl-10 {
  padding-left: 10px;
}

.pl-12 {
  padding-left: 12px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-7 {
  padding-left: 7%;
}

.stories-list ol li {
  padding: 15px 0px 15px 4.5% !important;
}

.daytags {
  width: fit-content;
  font-size: 16px;
}

.daytags span {
  font-weight: 400 !important;
  font-size: 16px;
}

.timeblock .numdes {
  font-size: 16px;
}

.summary_header div {
  font-weight: bold;
  font-size: 15px;
}

.summary_header th {
  font-weight: bold;
  font-size: 15px;
}

.appname span {
  margin-bottom: 10px;
}


.check_container {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 40px;
  cursor: pointer;
  z-index: 1;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check_checkmark {
  position: absolute;
  top: 14px;
  left: 10px;
  height: 17px;
  width: 17px;
  background-color: #a39999;
  border-radius: 40px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_container input:checked~.check_checkmark {
  background-color: #ffffff;
  border: solid 1.4px var(--primary);
}

/* Show the checkmark when checked */
.check_container input~.check_checkmark:after {
  display: block;
  top: 2px;
  left: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
}

.check_container input:checked~.check_checkmark:after {
  display: block;
  top: 1px;
  left: 5px;
  border: solid var(--primary);
  border-width: 0 2px 2px 0;
}

/* Style the checkmark/indicator */
.check_container .check_checkmark:after {

  width: 5px;
  height: 10px;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sltool-logos h5 {
  position: relative;
  display: flex;
}

.check_container .check_checkmark:after {
  border: solid #fff;
}

span.sllogo {
  position: relative;
  left: 15px;
  width: 80%;
}

.sllogo img {
  width: initial;
  height: -webkit-fill-available;
}

#Plan .sllogo img {
  width: 100%;
  height: inherit;
}

#Code .sllogo img {
  width: inherit;
  height: inherit;
}

#Deploy .sllogo img {
  width: initial;
  height: -webkit-fill-available;
}

#Package .sllogo img {
  width: inherit;
  height: -webkit-fill-available;
}

.tool-div {
  width: 180px;
  height: 45px;
}

.on_detail {
  display: flex !important;
}

.onboart_inputs {
  display: none;
}

.sltypes .frmgroup {
  margin: 0 !important;
}

.logico img {
  position: absolute;
  left: 10px;
  top: 14px;
}

.disabled_tools {
  pointer-events: none;
}

.sltools-container input {
  height: 0px;
  border: 1px solid var(--primary);
  background: none;
  padding: 17px 10px;
}

.sltools-container input.checkbox {
  padding: 0;
}

.sltool-right {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  max-height: 250px !important;
}

#Database_tools {
  display: none;
}

.form-check-input:checked[type="checkbox"] {
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
}

.cmpanel {
  // position: relative;
}

#erpanel {
  font-size: 14px !important;
  padding: 8px;
  width: 100%;
  background: whitesmoke;
  color: #f00;
  border: 1px solid #f00;
  text-align: center;
  border-radius: 6px;
  display: none;
  // position: absolute;
  top: -50px;
  word-break: break-word;
  // word-break: break-all;
}

.hovertip:before {
  left: -310%;
}

.btnswrap.btn-snd {
  justify-content: right;
  // margin: 27px;
}

.btnswrap.btn-dlt {
  justify-content: center;
  margin: 27px;
}

// .btnwrap.save-btn {
//   margin: 20px;
// }

.adduser-row {
  display: none;
}

.adduser-row.active {
  display: flex;
}

.toolright .outline-button {
  float: right;
  margin-bottom: 10px;
}

.toolleft {
  width: 100%;
}

.user_list {
  width: 100%;
  display: flex;
  margin-left: 40px;
  flex-wrap: wrap;
}

.usertagsnav {
  margin: 0 !important;
}

.selecttypes-radio .form-check .form-check-input {
  margin: 4px !important;
}

.modal-dialog.request_panal {
  max-width: 900px;
}

.myRequest_detail_head.row span {
  font-size: 16px;
  color: #4e4e4e;
  font-weight: 700;
}

.myRequest_detail_head.row span.data_part {
  color: var(--primary);
  margin-left: 10px;
}

.myRequest_detail_head .col-6 {
  margin-bottom: 10px;
}

.groups_title {
  width: 150px;
}

.accessmenber_name {
  font-size: 13px;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid;
  border-radius: 3px;
}

.mtlable {
  font-size: 13px;
}

.adpanel_show {
  background: #f4f4f4;
  border: 1px solid #79d2de;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}

.adpanel_show .header {
  font-weight: 800;
}

.mtlable.add {
  color: #24c616;
}

.mtlable.remove {
  color: #ff3463;
}

.myRequest_detail_head {
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.tb_head {
  margin-bottom: 10px;
  font-size: 15px;
}

.toolval_pan img {
  width: 100px;
}

.config_btn {
  padding: 10px;
  background: white;
  margin: 0;
}

/*.msg-popup {
    width: 30%;
    height: 100px;
    background: #e1ffe6;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
    border: 1px solid #5dd357;
    text-align: center;
    display: flex;
    animation: popup 0.7s;
    transition: all 5s ease-in-out;
}
.msg_text {
    align-self: center;
    color: #522e8e;
    width: 100%;
}
@keyframes popup {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50%{
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}*/

/*Radio*/
.radio_container {
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #dcdcdc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio_container:hover input~.radio_checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio_container input:checked~.radio_checkmark {
  background-color: var(--primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_container input:checked~.radio_checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio_container .radio_checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.bg-dark-blue {
  background-color: var(--blue) !important;
}

.bg-pink {
  background-color: var(--pink) !important;
}

.bg-blue {
  background-color: var(--blue-light) !important;
}

.bg-purple {
  background-color: var(--purple) !important;
}

.hovertipgnc {
  position: relative;
}

.hovertipgnc:before {
  background: #ece2ff;
  border-radius: 4px;
  color: var(--primary);
  border: 1px solid var(--primary);
  content: attr(data-tip);
  font-size: 14px;
   
  font-weight: 400;
  padding: 6px 10px;
  width: max-content;
  position: absolute;
  z-index: 10;
  left: -335%;
  bottom: 60px;
  font-family: sans-serif;
  transition: all 0.3s linear;
}

.hovertipgnc:hover:before {
  left: 5px;
}

.dropdown-container {
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1);
  list-style: none;
  margin: 0;
  padding: 0;
  top: 0;
  left: 64px;
  position: absolute;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid #18347c;
  /*transition: all 0.8s;*/
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  width: 214px;
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  transform: translateY(2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
// backdrop-filter: blur( 13px );
// -webkit-backdrop-filter: blur( 13px );
}
.dropdown-container.nodisplay{
  display: none;
}
.dropdown-container_child.active {
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
  backdrop-filter: blur( 13px );
-webkit-backdrop-filter: blur( 13px );
}

.dropdown-container_child {
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1);
  list-style: none;
  margin: 0;
  padding: 0;
  top: 33%;
  border: 1px solid #18347c;
  left: 214px;
  position: absolute;
  background-color: rgb(255 255 255);
  backdrop-filter: blur(12.6);
  border-radius: 8px;
  /*transition: all 0.8s ease;*/
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  width: 214px;
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  transform: translateY(2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

  backdrop-filter: blur( 6px );
  -webkit-backdrop-filter: blur( 6px );
}

.ldap_check {
  margin-top: 32px;
}

.loading .loader_overlay {
  display: none;
}

.loader_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 998;
  display: block;
  overflow: hidden;
}
.spin_overlay {
  background: #ffffff;
  z-index: 998;
  // margin-top: 5%;
  overflow: hidden;
  height: 100%;
  padding: 105px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}
.spin_overlayFamily {
  background: #ffffff;
  z-index: 998;
  margin-top: 5%;
  overflow: hidden;
  padding: 105px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}
.trsSeverity {
  background: #ffffff;
  z-index: 998;
  // margin-top: 5%;
  overflow: hidden;
  height: 82%;
  padding: 105px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}
.page_loader {
  height: 100%;
  margin-top: 20%;
  position: relative;
  display: grid;
  justify-items: center;
  color: var(--primary) !important;
}

.user_loader {
  margin-top: 15%;
}

.page_loading {
  align-self: center;
  color: var(--primary) !important;
}

.red_eff {
  color: #ec6666;
}

.yellow_eff {
  color: #e9d96d;
}

.green_eff {
  color: #198754;
}

.tooltip_on {
  position: relative;
  display: inline-block;
}

.tooltip_on .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-size: 12px;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-inner {
  width: 120px;
  background-color: #000000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  z-index: 1;
  bottom: 125%;
  transition: opacity 0.3s;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip_on .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip_on:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#deployment-top {
  width: 100%;
  text-align: center;
}

.configtable tr th {
  padding: 30px 23px 17px;
}

.bootstrap-select .dropdown-menu {
  border: 1px solid var(--primary);
}

#btntool {
  position: relative;
  right: 1.8%;
}

#btnuser {
  position: relative;
  right: 1.8%;
}

#Ldapbtn {
  position: relative;
  right: 1.8%;
}

#Smtpbtn {
  position: relative;
  right: 1.8%;
}

#myreqbtn {
  position: relative;
  top: -5px;
}

#inchange {
  top: 79%;
}

.analysisDrillChart #myChart {
  height: 70% !important;
}

.graphdes.drill_grap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.drill_grap .destxt {
  flex-basis: 25%;
  border: 1px solid #D5D5D5 !important;
  padding: 10px;
}

#usegroup1 {
  position: relative;
  top: 5px;
  left: -1px;
}

/*.usegroup1{
  position: relative;
  top: 0px;
}*/
#usegroup2 {
  position: relative;
  top: 5.4px;
}

#action {
  position: relative;
  left: -9.5%;
}

#adchange {
  top: 47%;
}

.topheader-left {
  display: flex;
}

#topheader_text {
  padding-right: 7px;
}

.topheader-left h4 {
  font-size: 20px;
  font-weight: normal;
}

#build_description .stories-list ol li {
  padding: 15px 0px 15px 5% !important;
}

.summary_table {
  width: 100%;
  padding: 0;
}

.actnav {
  // grid-template-columns: 75% 25%;
  grid-template-columns: auto;
}

.followup-btn {
  text-align: center;
  font-size: var(--font16);
   
  font-weight: var(--regular);
  display: inline-block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  padding: 10px 18px;
  width: 130px;
  border: 1px solid var(--primary);
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.aligndeploy {
  position: relative;
  height: 25vw;
}

.productivity-xaxis {
  transform: rotate(270deg);
  position: absolute;
  left: -3%;
  top: 40%;
  font-size: 12px;
  color: black;
}

.productivity-yaxis {
  text-align: center;
  font-size: 13px;
  color: black;
}

.buildmtx-xaxis {
  transform: rotate(270deg);
  position: absolute;
  left: -3%;
  top: 40%;
  font-size: 12px;
  color: black;
}

.buildmtx-yaxis {
  text-align: center;
  font-size: 13px;
  color: black;
}

#deployment-percentage {
  font-size: 22px;
  font-weight: 600;
  color: #000;
}


.tick line {
  visibility: hidden;
}

.flowacti-block .dark-Orange.active {
  background-color: #ff8000;
  border: 1px solid #ff8000;
}

.dark-Orange {
  border: 1px solid #ffc107;
}

.flow-predi-block .dark-Orange.active {
  background-color: #ff8000;
  border: 1px solid #ff8000;
}

// .floweffi-block .dark-Orange.active {
//   background-color: #ff8000;
//   border: 1px solid #ff8000;
// }
.flowacti-block .Green.active {
  background-color: #81A71A;
  border: 1px solid #81A71A;
}

.Green {
  border: 1px solid #81A71A;
}

.flowacti-block .yellow-1.active {
  background-color: #fec82f;
  border: 1px solid #fec82f;
}

.yellow-1 {
  border: 1px solid #fec82f;
}

.flowacti-block .light-Orange.active {
  background-color: #fda26b;
  border: 1px solid #fda26b;
}

.light-Orange {
  border: 1px solid #fda26b;
}

.ptxt {
  margin: -9px;
}

.graphblock .doraDivImg {
  width: 30px;
  height: 30px;
}

.gncfilter {
  grid-template-columns: 45% 70%;
  padding: 0 10px;
}

.label {
  font-size: 32px;
  font-weight: 300;
}

#piTable {
  border-collapse: collapse;
  width: 100%;
  height: 350px;
}

#piTable td,
#piTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.label2,
.label3 {
  font-size: 12px;
  font-weight: 300;
}

.comingSoon {
  width: 100%;
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
  font-size: 20px;
  color: var(--primary);
  font-weight: 600;
}

.date_dispay {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f2f4f2;
}

.count_dispay {
  padding: 6px 25px 0px -5px;
  border-radius: 4px;
  background: #f2f4f2;
  color: #808080;
  text-wrap: nowrap;
  cursor: pointer;
}

.count_dispay div {
  font-size: 25px;
  color: #808080;
}

.count_dispay.disabled {
  cursor: not-allowed;
}
.filterbutton{
  margin-left: 20px;
}
.date_dispay span {
  padding: 5px;
  font-weight: 800;
}
.filtergap{
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.p-0-10 {
  padding: 0 10px;
}

.p-0-5 {
  padding: 0 5px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-10 {
  margin-top: 10px;
}
.summarytable {
  width: 100%;
  margin-bottom: 20px;
}

.summarytable tbody tr {
  border-bottom: 2px solid #e0e0e0;
  padding: 12px;
}

.summarytable thead,
.summarytable tbody,
.summarytable tr,
.summarytable td,
.summarytable th {
  padding: 12px !important;
}

.count_dispay.active {
  background: #ebc145;
  color: #fff;
}

.count_dispay.pinkline.active {
  background: #ff0000;
  color: #fff;
}

.count_dispay.dark-blueline.active {
  background: #00a862;
  color: #fff;
}

.count_dispay.active div {
  color: #fff;
}

.filterDays {
  float: right;
}


#piTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #2c7bcd;
  color: white;
}

.barChartPii {
  height: 400px;
}

.piiTableHeight {
  height: 98.4%;
}

.piiFltrConntain {
  display: flex;
}

.piiChartFltr {
  width: 150px;
  margin-left: auto;
}

#AAtable {
  border-collapse: collapse;
  width: 100%;
  //  height: 350px;
  position: relative;
  font-size: small;
  white-space: nowrap;

}

#AAtable td,
#AAtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#AAtable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #2c7bcd;
  color: white;
  position: sticky;
  top: -1px;
}

.dashcol7 {
  border-radius: 6px;
  height: 150%;
  padding: 17px 20px;
  position: relative;
}

#image0 {
  margin-bottom: 14px;
}

// #image1{
//   position: relative;
//   height: 102px;
//   width: 66px;
//   top: -135px;
//   right: -40%;
// }

// #image2{
//   position: relative;
//   height: 111px;
//   width: 66px;
//   left: 75%;
//    top: -238px;
// }
// #image3{
//   position: relative;
//   height: 102px;
//   width: 66px;
//   left: 21px;
//   top: -200px;
// }
// #image4{
//  position: relative;
//   height: 102px;
//   width: 66px;
//   left: 40%;
//   top: -302px;
// }
// #image5{
//   position: relative;
//     height: 102px;
//     width: 66px;
//     left: 75%;
//      top: -404px;
// }
// .Activity1{
//   height: 71.5%;
// }
.ra1 {
  position: relative;
  color: black;
  font-size: 18px;
  font-weight: 600;

}

.rc10 {
  position: relative;
  font-size: 16px;
  color: white;
  background: green;
  border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;
}

.rc11 {
  position: relative;
  font-size: 16px;
  color: white;
  background: orange;
  border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;
}

.rc12 {
  position: relative;
  font-size: 16px;
  color: white;
  background: blueviolet;
  border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;
}

.rc13 {
  position: relative;
  font-size: 16px;
  color: white;
  background: brown;
  border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;
}

.rc14 {
  position: relative;
  font-size: 16px;
  color: white;
  background: darkblue;
  border-radius: 6px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;

}

.rc15 {
  position: relative;
  font-size: 16px;
  color: white;
  background: deeppink;
  border-radius: 5px;
  margin-left: 25%;
  margin-right: 25%;
  top: -10px;
}

.List1 {
  position: relative;
  height: 32%;
}

.left1 {
  text-align: left;
  font-size: 14px;
  color: black;
  position: relative;
  left: 32px;
}

.p1 {
  position: relative;
  left: 116px;
  background: #7c7ce2;
  border: 1px solid;
  color: white;
  border-radius: 4px;
  width: 71px;
  text-align: center;
  height: 24px;
}

.p2 {
  position: relative;
  left: 65px;
  background: #7c7ce2;
  border: 1px solid;
  color: white;
  border-radius: 4px;
  width: 71px;
  text-align: center;
  height: 24px;
}

.p3 {
  position: relative;
  left: 57px;
  background: #7c7ce2;
  border: 1px solid;
  color: white;
  border-radius: 4px;
  width: 71px;
  text-align: center;
  height: 24px;
}

.p4 {
  position: relative;
  left: 50px;
  width: 71px;
  text-align: center;
  height: 24px;
  background: #7c7ce2;
  border: 1px solid;
  color: white;
  border-radius: 4px;
}

.p7 {
  display: flex;
}

.ActivityChartFltr {
  margin-left: auto;
  width: 190px;
}

img.adminProfile {
  border-radius: 50%;
  height: auto;
  width: 75px;
}

.imgCenter {
  text-align: center;
}

.adminGraph {
  height: 350px;
}

.mail_send_box .btnwrap {
  border: none;
  padding: 20px 0 0;
  margin: 0 0 0;
}

#send_mail_btn {
  display: none;
}

#send_mail_btn.active {
  display: flex;
}

.select_check {
  display: none;
}

.select_check.active {
  display: block;
  text-align: center;
}

.filterDays {
  display: none;
}

.filterDays.active {
  display: block;
}

.pii_Table {
  font-size: 13px;
}

.pii_Table tr {
  word-break: break-word;
}

.pii_Table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #2c7bcd;
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}

// .appname h5 span {
//   width: 20px;
//   height: 20px;
//   font-size: 12px;
//   color: var(--black);
// }

.namedesign {
  font-size: 11px !important;
}

.pic1 {
  font-size: 12px;
  color: black;
  font-weight: 900;
}

.summarytable th {
  padding: 12px !important;
}

// .summarytable th{
// border: 1px solid #ddd;
// padding: 8px;
// }
.TrendAlert {
  text-align: center;
}

.TrendAlert img {
  width: 30px;
}

.highlighted {
  background-color: #d5e1f2;
  height: 161px;
  border: 1px solid;
}

.UserTrendAlert {
  position: relative;
  height: 50px;
  background: #49e22d;
  color: white;
  padding: 14px;
  animation: popup 0.7s;
  transition: all 5s ease-in-out;
}

.UserAlert {
  position: relative;
  top: -25px;
  color: white !important;
  font-weight: 600 !important;
}

.Userclose {
  position: relative;
  top: -14px;
  margin-left: 99%;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.sendAlert {
  font-size: 12px !important;
  color: black;
  font-weight: 800;
}

.adminbox {
  background: #f2f4f2;
  color: #808080;
  padding: 10px;
  text-align: center;
  max-height: 100px;
  overflow: hidden;
  font-size: 16px;
  height: 120px;
  border-radius: 4px;
  cursor: pointer;
}

.admincount {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.admintitle {
  font-size: 13px;
  bottom: 0px;
  position: sticky;
}

.adminbox.dark-Orange.active {
  background-color: #ff8000;
  border: 1px solid #ff8000;
  color: var(--white) !important;
}

.adminbox.dark-blueline.active {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white) !important;
}

.adminbox.purpleline.active {
  background-color: var(--purple);
  border: 1px solid var(--purple);
  color: var(--white) !important;
}

.adminbox.pinkline.active {
  background-color: var(--pink);
  border: 1px solid var(--pink);
  color: var(--white) !important;
}

.adminbox.yellowline.active {
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
  color: var(--white) !important;
}

.adminbox.orangeline.active {
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white) !important;
}

.tableTitle {
  font-weight: var(--bold);
  text-transform: capitalize;
  margin-bottom: 12px;
  text-align: left;
}

.projectemty {
  background: white !important;
  color: var(--primary) !important;
  text-align: left !important;
  position: relative !important;
  text-align: center !important;
  // Commented because of the spinner border issue in tbody
  // padding: 12px !important;
  // height: 50px !important;
  // top: 0px !important;
  // bottom: 10px !important;
  padding: 10px 20px;
  font-size: 13px !important;
  font-weight: bold !important;
  border: 2px solid #F2F4F2 !important;
}

/*-----------------------------------------admin details css------------------*/

.bg-for-navBar {
  background-color: #D9D9D9;
}

#container {
  width: 145px;
  height: 100px;
  border: 1px solid #ccc;
  overflow-x: scroll;
}

#content {
  width: 250px;
  background-color: #ccc;
}

.cardcol h2 {
  font-size: 45px;
  font-weight: var(--regular);
   
  margin-bottom: 8px;
}

.card_head {
  font-size: var(--font12);
  font-weight: var(--medium);
   
  color: var(--black);
  text-transform: capitalize;
  margin-bottom: 12px;
}

.cardcol {
  border-right: 1px dashed #848484;
}

.cardcol:last-child {
  border-right: 0;
}

.cardcol:nth-child(2) {
  border-right: 0;
}

.list-group-item {
  padding: 10px 0;
}

// .totalyear {
//   // justify-content: space-between;
//   // align-items: flex-start;
//   // display: flex;
// }
.totalyear span {
  font-size: 12px;
}

.totalyear h2 {
  font-size: 45px;
  font-weight: var(--regular);
   
  color: #21a366;
  margin-bottom: 25px;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: auto;
}

.custom_table_vul {
  font-size: 13px;
  // width: max-content;
  width: -webkit-fill-available;
  white-space: nowrap;
}

.pm-0 {
  padding: 0;
  margin: 0;
}

#aadNav {
  display: flex;
  width: 100%;
  background: #ffff;
  border-radius: 10px;
}

.dashTopNav {
  max-width: 100%;
}

.dashTopNav .nav_1 {
  padding: 13px 15px;
  background-color: #e8e8e8;
  white-space: nowrap;
  font-size: var(--font13);;
}

.m-nav {
  position: relative;
  width: inherit;
  margin: 8px 18px 8px;
  padding: 3px;
}

#complainsesider {
  height: 45px;
  /* max-height: 1000px; */
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: inline-block;
}

#outsider {
  height: 25px;
  /* max-height: 1000px; */
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: inline-block;
}

div#insider {
  position: absolute;
  display: flex;
  height: auto;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
}

.m-nav li {
  display: inline-block;
  list-style-type: circle;
}

.m-nav a {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  color: var(--gray);
  padding: 10px 18px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  position: relative;
}

.directionnav {
  display: flex;
  margin: 0 18px 0 auto;
  align-items: center;
}

.directionnav #button-left {
  margin-right: 15px;
}

.directionnav svg {
  width: 10px;
}

#button-left,
#button-right {
  cursor: pointer;
}

#basic-navbar-nav {
  font-size: 15px;
}

#basic-nav-dropdown {
  white-space: nowrap;
  // background-color: #D9D9D9;
  padding: 13px 15px;
  font-size: var(--font13);
   
  font-weight: var(--semibold);
  border: 0;
  border-radius: 6px px 0 0 6px;
  color: var(--black);
  width: 150px;
  text-align: left;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#aadNav .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 44%;
  display: none;
}

.list_group {
  text-align: left;
  font-size: 14px;
}

#scv_counts {
  margin: 23px 0px;
}

#table_vul_Responsive {
  height: 300px;
}

.custom_table_vul thead {
  position: sticky;
  top: 0;
  background: white;
}

.buildurl {
  color: #0d6efd;
  text-decoration: underline;
}

.topassigneeTop {
  margin-top: 0px;
}

.topassigneeTableTittle {
  font-size: 16px;
  color: #000;
  font-weight: var(--bold);
  margin-top: 20px;
  margin-bottom: 20px;
}

.flowPredictTableTitle {
  font-size: 14px;
  color: #000;
  font-weight: var(--bold);
  margin-top: 20px;
  margin-bottom: 20px;
}

.width50Per {
  width: 50%;
}

.width10Per {
  width: 10%;
}

.width20Per {
  width: 20%;
}

.width5Per {
  width: 10%;
}

#VTC_summary {
  width: 100%;
}

// Flow Efficiency Percentage color
.floweffi-block .active .below20 {
  background-color: var(--pink);
  border: 2px solid var(--pink) !important;
}

.floweffi-block .active .below70 {
  background-color: #ff8000;
  border: 2px solid #ff8000 !important;
}

.floweffi-block .active .below100 {
  background-color: #018001c2;
  border: 2px solid #018001c2 !important;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: rgb(255, 255, 255);
  }
}

.loaderIssue {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: .25rem solid #f3f3f3;
  display: none;
  border-top-color: #3498db;
  animation: spin 1s infinite linear;

  &--double {
    border-style: double;
    border-width: .5rem;
    margin: 0px 0px -19px -8px !important;
  }
}




.alert_mail {
  padding: 8px 20px!important;
}

// .modal-dialog.Automated {
//   max-width: 900px;
// }

.days_inactive {
  padding: 5px 20px;
  text-align: center;
  border-radius: 6px;
  background: #a6ce38;
  width: 50%;
  margin: auto;
  border: none;
}

.days_inactive.active {
  background: var(--primary);
  color: white;
}

.days_inactive:hover {
  background: var(--primary);
  color: white;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.textarea_frmgroup {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  color: var(--gray2);
  background-color: var(--lightbg);
  width: 100%;
  padding: 20px 24px;
  border-radius: 6px;
  border: 0;
  height: 50vh;
}

// .nav-tabs .nav-link.active {
//   color: #ffffff;
//     background-color: var(--primary);
// }

// .nav-tabs .nav-link {
//   color: #121212;
// }

.frmhead label {
  font-size: 13px;
}

.drilldownThree_backdrop {
  z-index: 1045;
}

.offcanvas.offcanvas-end.custom-off-canvas.drilldownThree {
  width: 80%;
  padding: 10px;
}
.offcanvas.offcanvas-end.custom-off-canvas.drilldownLast {
  width: 70%;
  padding: 10px;
}
.drilldownThree {
  font-weight: 500;
}

.Svalue_drill {
  font-weight: normal;
}

.clickable_Id {
  cursor: pointer;
  text-decoration: underline;
  color: #0d6efd;
}
.hover_Id {
  cursor: pointer;
  color: #0d6efd;
}
/*-----------date picker----------------*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  margin: 139px 0px 0px 10px;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #80808000 !important;
}

.react-date-picker__button:enabled {
  cursor: pointer;
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  font-size: 12px;
}

.react-calendar__month-view__days__day--weekend {
  color: #420000 !important;
}

.react-calendar__tile--now {
  background: #522e8e00 !important;
  color: #000000 !important;
  border-radius: 4 !important;
}

.react-calendar__tile--active {
  background: var(--primary) !important;
  color: white !important;
  border-radius: 4px;
}

button.react-calendar__navigation__label {
  font-size: 12px;
  font-weight: 700;
}

.react-calendar__month-view__weekdays {
  font-size: 11px !important;
  text-transform: capitalize !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-date-picker__calendar.react-date-picker__calendar--open {
  inset: auto 0px 0% auto !important;
}

.actionDescription {
  font-size: 18px !important;
}
.detailpopup{
  padding: 40px;
  display: grid;
  justify-items: center;
}

.limit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  /* change this value to adjust the maximum width of the td */
}

.vulnerabilityFontSize {
  font-size: 15px !important;
  margin: 29px 4px 6px 0px;
}

.infoBody p {
  font-size: 15px !important;
}

.modelButton {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:active {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:hover {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

.modelButton:active {
  background-color: #c6c7c800 !important;
  border-color: #babbbc00 !important;
}

@media only screen and (min-width: 800px) {
  .actionPlan ul li {
    margin: 0px 8px;
  }

  .actionPlanUlil {
    display: flex;
    float: right;
  }

  .approvePlan {
    float: right;
  }

  .cisoView h3 {
    border: 1px solid black;
    width: 25%;
    margin: auto;
    text-align: center;
    background-color: #4472c4;
    color: white;
  }

  .footerWrapCustom {
    flex-wrap: inherit !important;
  }

  .vulnerablityButton {
    // float: right;
    margin: 0px 0px -37px 0px;
  }
  .userReviewButton {
    // float: right;
    margin: 0px 200px -37px 0px;
  }
}

.approveModel p {
  font-size: 15px !important;
}

.cisoTable tr:hover {
  background-color: #f6f6f6 !important;
}

.cisoTable tr th {
  font-size: 14px;
   
  font-weight: var(--bold);
  color: var(--text);
  padding: 30px 20px 17px;
}

.cisoTable tr td {
  font-size: 14px;
   
  color: var(--text);
  font-weight: var(--regular);
  padding: 10px 20px;
}

.securityTable tr:hover {
  background-color: #f6f6f6 !important;
}

.securityTable tr th {
  font-size: 14px;
   
  font-weight: var(--bold);
  color: var(--text);
  padding: 30px 20px 17px;
}

.securityTable tr td {
  font-size: 14px;
   
  color: var(--text);
  font-weight: var(--regular);
  padding: 10px 20px;
}

.tooltip {
  display: inline;
  position: relative;
  opacity: inherit;
  text-align: center;
}

// .tooltip:hover:after {
//   display: -webkit-flex;
//   display: flex;
//   -webkit-justify-content: center;
//   justify-content: center;
//   background: #00000082;
//   border-radius: 8px;
//   color: #fff;
//   content: attr(title);
//   font-size: 10px;
//   padding: 1px;
// }

// .tooltip:hover:before{
//   border: solid;
//   border-color: #444 transparent;
//   border-width: 12px 6px 0 6px;
//   content: "";
//   position: absolute;
// }

.infoBody {
  height: 300px;
  overflow-y: auto;
}

// .react-calendar {
//   width: 350px;
//   max-width: 100%;
//   background: white;
//   border: 1px solid #a0a09600!important;
//   font-family: Arial, Helvetica, sans-serif;
// }
// button.react-calendar__tile.react-calendar__month-view__days__day {
//   font-size: 10px!important;
// }
// .react-calendar__tile {
//   max-width: 100%;
//   padding: 0px 0px!important;
//   background: none;
//   text-align: center;
// }
// .react-calendar__month-view__weekdays {
//   font-size: 9px !important;
//   text-transform: capitalize !important;
// }
// .react-calendar__navigation {
//   display: flex;
//   height: 20px!important;
//   margin-bottom: 0em!important;
// }
// button.react-calendar__navigation__label {
//   font-size: 9px!important;
//   font-weight: 700;
// }
// abbr[title] {
//   -webkit-text-decoration: underline dotted;
//   text-decoration: none;
//   cursor: help;
//   text-decoration-skip-ink: none;
// }
// .react-calendar__month-view__days__day--weekend {
//   color: #2f2f2f;
// }
// .react-calendar__navigation button {
//   min-width: 27px!important;
//   background: none;
// }
.actright {
  justify-content: flex-start;
}
.gapformonth{
  margin-bottom: -10px;
}
.modal .btn-close {
  box-sizing: content-box;
  padding: 0;
  color: var(--primary);
  background: none;
  border: 0;
  border-radius: 0;
  opacity: 1;
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  right: 5px !important;
  top: 3px !important;
  z-index: 999;
  font-family: "Barlow", sans-serif;
}

/* width */
.infoBody::-webkit-scrollbar {
  width: 10px;

}

/* Track */
.infoBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.infoBody::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}

/* Handle on hover */
.infoBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.topTable {
  height: 240px;
  overflow-y: auto;
}

.topTable .text-nowrap::-webkit-scrollbar {
  width: 2px !important;
}

/* Track */
.topTable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.topTable::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.topTable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom_scroll1 {
  height: 241px;
  border: none;
}

.custom_scroll1::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */

.custom_scroll1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.custom_scroll1::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 10px;
}

/* Handle on hover */

.custom_scroll1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.width15 {
  width: 15%
}

.width10 {
  width: 10%
}

.width60 {
  width: 60%
}

.padding25 {
  padding: 25px !important;
}

.LocIssueTittle {
  font-size: var(--font20);
   
  color: var(--primary);
  font-weight: var(--extrabold);
  margin-bottom: 30px;
}

.un_id {
  text-decoration: underline;
  text-decoration-color: #0d6efd;
  color: #0d6efd;
  cursor: pointer;
}

.followup-btn:hover {
  background-color: white;
  color: var(--primary);
}

#checkBoxFixed {
  position: sticky;
  left: 0;
  //background: white;
}

svg#SvgjsSvg1131 {
  height: 350px !important;
}

.tableColorBackground {
  background-color: #e9e5e5;
  color: black;
}

.tableColorBackground #checkBoxFixed {
  background: #e9e5e5;
}

@media screen and (min-width: 1415px) {
  .vulmax {
    width: 100%;
    height: 44.5%;
  }
}

.autocomplete {
  position: relative;
}

.autocomplete input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.autocomplete ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.autocomplete li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete li:hover {
  background-color: #f2f2f2;
}

.custom-select-server {
  margin-bottom: 27px !important;
}

.server-btn {
  text-align: end;
}
// .server-btn button:last-child{
//   margin-right: 0;
// }
.eMail-btn button:last-child{
  margin-right: 5px !important;
}
.m-r-15 {
  margin-right: 15px;
}

.srvr-close-btn {
  display: flex;
}

.srvr-close-btn svg {
  font-size: 19px;
  margin-left: auto;
}

.diablecheck {
  cursor: no-drop;
}

.scorecrdTittle {
  text-align: center;
}

.dropdown-container_child.CMDB.index5 {
  top: 15%;
}
.dropdown-container_child.admin.index5 {
  top: 50%;
}
.dropdown-container_child.CMDB.index4 {
  top: 20%;
}
.dropdown-container_child.settings.index4 {
  top: 40%;
}
.dropdown-container_child.admin.index4 {
  top: 60%;
}
.dropdown-container_child.CMDB.index3 {
  top: 25%;
}
.dropdown-container_child.settings.index3 {
  top: 50%;
}
.dropdown-container_child.admin.index3 {
  top: 75%;
}
.dropdown-container_child.admin.index2 {
  top: 65%;
}
.dropdown-container_child{
  max-height: 180px;
  overflow-y: auto;
}
.custom-select-server {
  margin-bottom: 50px;
  // margin-right: 100px;
}

.server-btn {
  text-align: end;
}

.m-r-15 {
  margin-right: 15px;
}

.business-close-btn {
  display: flex;
  flex-direction: row-reverse;
}

.back-close {
  margin: 0 -16px !important;
}
.actionsBtn a {
  padding: 0px;
  flex-basis: 50%;
}
// .Servertdactions {
//   margin: 0px -2px 0px -19px !important;
// }
// .groupsAlignPadding{
//   margin: 0px -16px 0px -22px;
// }

.business-center {
  padding: 0px 0px 0px 26px !important;
}

.custom-select-business {
  margin-bottom: 40px;
  margin-right: 35px;
}

.busineswhite {
  flex: 0 0 auto;
  width: 27.333333%;
}

.businesstwo {
  flex: 0 0 auto;
  width: 72%;
}

.clsbusiness {
  margin-right: 11px;
}

.clsbusinesss {
  margin-right: 11px;
  padding: 6px;
  height: 34px;
}

.svebusiness {
  margin-right: 11px;
}

.svebusine {
  padding-bottom: 21px;
}

.Plusaddbutton {
  font-size: 20px;
  border-radius: 34px;
  background: white;
  float: right;
  padding: 0 10px;
}
.input-wrapper {
  position: relative;
}

.business-add {
  /* Add any additional styling you want for the input field */
  position: relative;
}

.remove-button-business {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.businessAlign {
  top: 158px !important;
}

.CloudAlign {
  top: 230px !important;
}

.serverAlign {
  top: 247px !important;
}
.userAlign {
  top: 270px !important;
}
.userinfoAlign{
  top: 247px !important;
}

.formColUser {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 269px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.formCol {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 165px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

#tableCol {
  transition: 0.5s;
  padding: 16px;
}

.capitalize {
  text-transform: capitalize;
}

.topCname {
  color: #000;
  font-size: 16px;
   
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 1260px) {
  .col-xl-4.col-lg-4.col-md-12.vulReltion.filtercol {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

@media (max-width: 860px) {
  .col-xl-4.col-lg-4.col-md-12.vulReltion.filtercol {
    flex: 0 0 auto;
    width: 100%;
  }
}

.tdico {
  color: var(--primary);
}

.custom_margin_right_active {
  margin: 0px 0px 0px -67px;
}

.custom_margin_right {
  margin: 0px 110px 0px -87px;
}

@media screen and (min-width: 1440px) {
  .custom_margin_right_active {
    margin: 0px 0px 0px -64px;
  }

  .custom_margin_right {
    margin: 0px 110px 0px -127px;
  }
}

#BusinessActivity {
  width: 88% !important;
}

.businesscontrol {
  width: 100% !important;
  border: 1px solid var(--secondary-light);
}

.businesCol {
  width: 98%;
}

.businessfount {
  font-size: 14px !important;
}

#PluginClick {
  cursor: pointer;
  color: #0d6efd;
  text-decoration: underline;
}

#PluginClick:hover {
  text-decoration: none;
  transition: all 0.4s linear;
}

.meanpop-head1 {
  padding: 27px 38px 0;
}

.countDeploy {
  justify-content: space-between;
  padding: 0 10px 0 20px;
  width: 80%;
}

.sitdeploymentmetrics {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 16vw;
}

.businessall {
  margin: 51px 0px 0px 0px;}

.incidintfont {
  font-size: 13px !important;
}

.oFWrap {
  overflow-wrap: anywhere;
}

#VTC_summary tbody {
  vertical-align: -webkit-baseline-middle;
}

.incidentValue {
  font-size: 14px;
}

.noMsgData {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white !important;
  color: #6f42c1 !important;
  text-align: center !important;
  padding: 12px !important;
  font-size: 11px !important;
  font-weight: bold !important;
  width: 100%
}

.buildmetrics_chart {
  width: 18vw;
  height: 18vw;
}
.codeAnalysis_chart {
  width: 28vw;
  height: 21vw;
}

.logintrend #mainchart {
  height: 350px;
}

.set_fix_active {
  height: 18vw;
}

.set_fix_height {
  height: 17vw;
}

.set_fix_height_project {
  height: 17vw;
}

.set_fix_bar_custom {
  height: 23vw;
}

.set_fix_proj {
  height: 26vw;
}

.set_fix_lel {
  height: 24vw;
}

@media screen and (max-width: 1100px) and (min-width: 0px) {
  .set_fix_active {
    height: 22vw;
  }

  .set_fix_proj {
    height: 26vw;
  }

  .set_fix_lel {
    height: 26vw;
  }

  // .set_fix_height{
  //   height: auto;
  // }
  // .set_fix_height_project{
  //   height: auto;
  // }
  // .set_fix_bar_custom{
  //   height: auto;
  // }
}


@media screen and (max-width: 2000px) and (min-width: 1101px) {
  .set_fix_active {
    height: 20vw;
  }

  .set_fix_proj {
    height: 23vw;
  }

  .set_fix_lel {
    height: 24vw;
  }

  // .set_fix_height{
  //   height: auto;
  // }
  // .set_fix_height_project{
  //   height: auto;
  // }
  // .set_fix_bar_custom{
  //   height: auto;
  // }
}

@media screen and (max-width: 3100px) and (min-width: 2001px) {
  .set_fix_active {
    height: 19vw;
  }

  .set_fix_proj {
    height: 23vw;
  }

  .set_fix_lel {
    height: 24vw;
  }

  // .set_fix_height{
  //   height: 20vw;
  // }
  // .set_fix_height_project{
  //   height: 20vw;
  // }
  // .set_fix_bar_custom{
  //   height: 25vw;
  // }

}

.devopsGraph-container1 {
  position: relative;
  width: 25vw;
  height: 15vw;
}

.devopsGraph-container2 {
  position: relative;
  width: 35vw;
  height: 18vw;
}

.devopsGraph-container3 {
  position: relative;
  width: 25vw;
  height: 15vw;
  top: 55px;
}

.tableRedHighlight {
  color: red;
}

.chartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sixChart {
  display: inline-block;
}

.reqest-center {
  text-align: center;
}

.codeanalysis {
  width: 100%;
  height: 100%;
}

.codeGraph {
  position: absolute;
  height: 80%;
  width: 100%;
}

.incident-table {
  width: 100% !important;
}

.incidnt-meantime {
  width: 100% !important;
}

.btn-tool {
  margin-left: 0px !important;
  align-items: center;
}

.incident-box {
  margin-right: -1%;
}

.btn-group {
  margin-left: 1px !important;
}

.analysisChart {
  height: 42vw;
  width: 100%;
}

.locChart {
  height: 25vw;
}

.chartHightVW {
  height: 21vw;
}

#contributorsContainer .contricol {
  height: 20vw;
}

.main-table {
  border-collapse: collapse;
  width: 100%;
}

.main-table th,
.main-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.sub-table {
  border-collapse: collapse;
  width: 100%;
}

.sub-table th,
.sub-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: #f2f2f2;
}

.downloadAcounts {
  margin-right: 20px;
}

.center-v-h {
  padding: 120px 0;
  text-align: center;
}

.centerTransform {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.efficieny1 {
  position: relative;
  bottom: 70px;
}

.severity-badge {
  display: inline-block;
  width: 85px;
  border-radius: 3px;
  text-align: center;
}

.axislabel-velocity {
  font-size: 12px;
}
.Search_Table{
  margin-top: 40px;
}
#dilldown2_littel_tablwe{
  width: 50%;
}

.severity-badge-c {
  display: inline-block;
  width: 100px;
  border-radius: 3px;
  text-align: center;
}

.severity-badge_critical {
  background-color: #ff7575;
  border: solid 1px #f00;
}

.severity-badge_low {
  background-color: #aaf9ff;
  border: solid 1px #00ecff;
}

.severity-badge_midum {
  background-color: #f5e97f;
  border: solid 1px #ffe500;
}

.severity-badge_high {
  background-color: #ffdc9c;
  border: solid 1px #ffa500;
}

.severity-badge_informational {
  background-color: #DEEBF7;
  border: solid 1px #a7cbe3;
}

.severity-badge_notAvailable {
  background-color: #c5d6fd;
  border: 1px solid #95b0f0;
}

.status-badge_passed {
  background-color: #00a862;
  border: solid 1px #37c400;
}

.status-badge_failed {
  background-color: #f84821a1;
  border: solid 1px #ff3002;
}
//flow metrics
.status-badge_NotWorked {
  background-color:  #ff7575;
  border: solid 2px #f00;
}
.status-badge_InProgress {
  background-color: #f5e97f;
  border: solid 2px #ffe500;
}
.status-badge_Completed {
  background-color: #71FF86;
  border: solid 2px #27CA40 ;
}
//flow metrics
.status-badge_w {
  background-color: white;
  border: solid 1px #0a0a09;
}
.status-badge_n_a {
  background-color: #ffd68b3d;
  border: solid 1px #ffd68bd6;
}
.userStatus-badge_Retained {
  background-color: #198754;
  border: solid 1px #c0a1a1;
}
.userStatus-badge_Revoked {
  background-color: #dc3545;
  border: solid 1px #c0a1a1;
}
.userStatus-badge_Pending {
  background-color: #fdca6b;
  border: solid 1px #c0a1a1;
}
// .accordion-collapse {
//   background: #f3f3f3;
//   text-align: initial;
// }

.navli {
  padding: 10px 0px !important;
}

.bg-Trmg {
  // background: white;
  color: black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.bg-Vm {
  // background: white;
  color: black;
  cursor: pointer;
}

.bg-Shf {
  // background: white;
  color: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.bg-Circular-09 {
  color: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.bg-highlightTRMG {
  background-color: rgb(255 165 0 / 64%);
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bg-highlightVM {
  background-color: mediumaquamarine;
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bg-highlightSHF {
  background-color: rgb(255 20 147 / 50%);
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bg-highlightCircular {
  background-color: rgb(138 40 225 / 53%);
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

///loder

.panel.panel-filled .panel-body {
  padding-top: 10px;
}

.ld-loading .panel-body {
  position: relative;
}

.ld-loading .panel-body>* {
  transition: opacity 0.3s;
  opacity: 0.2;
}

.ld-loading .panel-body:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
}

.ld-loading .loader_table {
  display: block;
}

.loader_table {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100px;
  width: 100%;
  background-color: white;
  // top: 531px;
}
.loader_table_hub {
  height: 100px;
  width: 95%;
  margin: auto;
  background-color: white;
  border-radius: 6px;
  padding: 17px 20px;
}

.loader-bar,
.loader-bar:before,
.loader-bar:after {
  background: var(--primary);
  -webkit-animation: load-bar-an 1s infinite ease-in-out;
  animation: load-bar-an 1s infinite ease-in-out;
  width: 0.66em;
  height: 0.66em;
}

.loader-bar {
  color: var(--primary);
  text-indent: -9999em;
  margin: auto auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: 50px;
}

.loader-bar:before,
.loader-bar:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader-bar:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bar:after {
  left: 1em;
}

@-webkit-keyframes load-bar-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

@keyframes load-bar-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

/* styles.css */

.severity_low {
  background-color: #aaf9ff;
  border: solid 1px #00ecff;
  padding-left: 0.76cm;
  padding-right: 0.76cm;
  padding-top: 0.21em;
  padding-bottom: 0.21em;
  border-radius: 3px;
  font-size: 0.75em;
  font-weight: 700;

}

.severity_medium {
  background-color: #f5e97f;
  border: solid 1px #ffe500;
  padding-left: 0.55cm;
  padding-right: 0.55cm;
  padding-top: 0.21em;
  padding-bottom: 0.21em;
  border-radius: 3px;
  font-size: 0.75em;
  font-weight: 700;


}

.severity_high {
  background-color: #ffdc9c;
  border: solid 1px #ffa500;
  padding-left: 0.75cm;
  padding-right: 0.75cm;
  padding-top: 0.21em;
  padding-bottom: 0.21em;
  border-radius: 3px;
  font-size: 0.75em;
  font-weight: 700;
}


.severity_critical {
  background-color: #ff7575;
  border: solid 1px #f00;
  padding-left: 0.58cm;
  padding-right: 0.58cm;
  padding-top: 0.21em;
  padding-bottom: 0.21em;
  border-radius: 3px;
  font-size: 0.75em;
  font-weight: 700;
}
.unselected .nav_2 {
  font-size: 13px;
  background-color: #ffff !important;
}
.dashTopNav .nav_2 {
  padding: 13px 15px;
  background-color:  #ffff;
  white-space: nowrap;
  font-size: var(--font13);;
}

#manageComplianceButton:disabled {
  background-color: var(--primary);
  border: solid 1px var(--primary);
  color: white;
}

.no-data {

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #042350 !important;
  font-size: 13px !important;

  font-weight: bold;

}

.modelBodyResources {
  word-wrap: break-word;
}

.naveLinkLi .nav-list {
  list-style: none;
  padding: 0;
}

.naveLinkLi .nav-list li {
  margin-bottom: 10px;
}

.naveLinkLi .nav-list li .bullet {
  margin-right: 5px;
  color: var(--primary);
}

.CIS-score {
  padding-left: 72px !important;
}

#aadNav ul li a.selected {
  display: none;
}

a {
  color: #000000;
}

#basic-nav-dropdown #nav-dropdown {
  padding: 0;
}

.meanpop-subhead ul {
  display: flex;
  flex-wrap: wrap;
}

.meanpop-subhead li {
  flex-basis: 100%;
  display: flex;
}

// .meanpop-subhead li span:first-child {
//   min-width: 130px;

//   /* Adjust the width as needed */
// }


.manageDisabled:disabled {
  background-color: var(--primary);
  border: none;
  font-size: 13px;
  padding: 8px;
}

.manageEnabled {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  font-size: 13px;
  padding: 8px;
}
.manageEnabled:hover {
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}


/* width */
@-moz-document url-prefix() {
  .graphScroll,
  .custom_scroll,
  .flowDrill_scroll,
  .deployoverflow-scroll {
    overflow: auto;
    scrollbar-color: #adadad #f1f1f1;
    scrollbar-width: thin;
  }
}

.graphScroll::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.graphScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.graphScroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}

/* Handle on hover */
.graphScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-modal_custom {
  width: 90vw;
  max-width: 90vw;
  border-radius: 10px;
  margin-left: 7%;
}

/* Default styles for the X-axis and Y-axis labels */
.xAxisLabel,
.yAxisLabel {
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

.yAxisLabel {
  writing-mode: vertical-lr;
  /* Use vertical-lr to position labels to the side */
  transform: rotate(180deg);
  /* Rotate labels 180 degrees for correct orientation */
  white-space: nowrap;
  /* Prevent label text from wrapping */
  // margin-left: -17px;
  margin-top: 28px;
  position: absolute;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {

  .xAxisLabel,
  .yAxisLabel {
    font-size: 12px;
    padding: 0px;
  }
}

/* Responsive styles for larger screens */
@media (min-width: 1200px) {

  .xAxisLabel,
  .yAxisLabel {
    font-size: 13px;
    padding: 0px;
  }
}

#comlince_drill_details.meanpop-subhead ul {
  display: -webkit-box;
  margin-bottom: 5px;
}

#comlince_drill_details.meanpop-subhead li {
  flex-basis: auto;
}

.cisostatus {
  margin-bottom: 40px;
}

.graph-des-nav li span.planned {
  background-color: #228b22;
}

.graph-des-nav li span.unplanned {
  background-color: #ff0404;
}

.graph-des-nav li span.exception {
  background-color: #fda26b;
}

.graph-des-nav li span.whitelisted {
  background-color: #79D2DE;
}

.graph-des-nav li span.approval_pending {
  background-color: #a9a9a9;
}


.m-nav ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #505050c4;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
}

// homepage
.info-box {
  padding: 9px 10px;
  border-bottom: 1px solid #E4E4E4;
  text-align: left;
  margin-bottom:10px;
  cursor: pointer;
}

.info-box h4,
.info-box p {
  margin: 0;
}

.info-box p {
  font-size: 13px;
  font-weight: normal;
}

.info-box h4 {
  color: var(--text);
  text-transform: none;
  margin-bottom: 6px;
}

.defaultCursor {
  cursor: default;
}

.border-table table tr.thead {
  border-bottom: 3px solid var(--primary);
}

.border-table table tr {
  border-bottom: 1px solid var(--border);
}

.border-table table tr th {
  font-weight: var(--semibold);
   
  color: var(--text);
  padding: 14px 14px;
  white-space: nowrap;
  text-align: initial;
}

.border-table table tr td {
  font-size: var(--font13);
  font-weight: var(--regular);
  color: var(--text);
  padding: 14px 14px;
  text-align: initial;
  vertical-align: top;
}

.scroll-verticle {
  max-height: 300px;
}

.sticky_top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky_bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.sort-icon {
  /* Reduce the fill color */
  fill: #888;
  /* Adjust the opacity */
  opacity: 0.6;
}

.sorted-icon {
  /* Reduce the fill color */
  fill: #3f91d8;
}

.search_class {
  display: block;
  width: 100%;
  padding: 0.575rem 0.75rem;
  font-size: 13px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0px 0px 0px -4px;
}

.search_user {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0px 0px 0px -4px;
}

.webKitCenter {
  text-align: -webkit-center;
}

// #home_otherApp{
//   display: none;
// }
.inherit {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0px 0px 0px -15px;
}

.added {
  font-size: var(--font14);
  font-weight: var(--regular);
  display: inline-block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  padding: 15px 20px;
  border: 1px solid var(--primary);
  text-transform: capitalize;
  transition: all 0.4s linear;
}

.Servercorrection {
  // display: flex;
  // justify-content: flex-start;
  margin: 0px -22px 0px -54px !important;
}

.customSelectStyle {
  width: 160px;
}

.checkBoxAb {
  position: absolute;
  margin: 8px 0px 0px -6px;
}

.text-align-start {
  text-align-last: start;
}

.table-search {
  margin-top: 20px;
}

.float-right {
  float: right;
}

th.noSort span {
  display: none;
}
.center-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tablefont{
  font-weight: 400;
}
.vulnerablityButtoninvisible {
  margin: 0px 0px -54px 0px;
}
.vulnerablityButtoninvisibleData {
  margin: 0px 178px -54px 0px;
}
// .search_class1
// {
//   display: block;
//     width: 100%;
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     color: #212529;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     appearance: none;
//     border-radius: 0.375rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     margin: 0px 0px 0px -4px;
// }
.tabs .tabsClass{
  display: flex;
  justify-content: flex-start;
}

.tabs button {
  padding: 10px;
  margin-right: 10px;
  border: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
}

.tabs button.active {
  font-weight: bold;
}

.bg-light_custom {
  background-color: white!important;
}
.ChatBot{
  margin-top: 1px;
  }
.ChatbotAlign{
  // padding-left: 90%;
  // position: relative;
  float: right;
  cursor: pointer;
}
.Chatbotimg{
  text-align-last: center;
}
#align-query{
  height: 80px;
  // width: auto;
}
.ChatbotAlign_Query {
  height: 40px;
  width: auto;
  // padding-left: 90%;
  position: relative;
  float: right;
}
.response-text {
  background-repeat: no-repeat;
  background-position: center;
  color: green;
}
.ChatBotQuery{
  color: #030303;
  background-repeat: no-repeat;
  background-position: center;
}
.tableDownForCiso{
  margin-top: 50px;
}
// .alignAccount{
//   padding-left: 40px !important;
// }
.userCount{
  padding-left: 7px !important;
}

.customLabelSecub {
  color: var(--primary);
}

.button_custom {
  background-color: #61a1fe !important;
  color: white !important;
  border-radius: 5px !important;
}

.optionBar{

  width: 200px;
  margin-right: 5px;
}
.appGroupWidth{
  width: 100%;
  // margin-top: -88px;
}
.desAlign{
  position: absolute;
  margin-top: 2px;
  width: 29%;
}
#Description{
    min-height: 6em;
      max-height: 22.5vw;
      width: 100%;
      background: #f2f4f2;
      resize: none;
      color: var(--gray3);
      font-size: var(--font13);
      padding: 16px;
      border: none;
      border-radius: 6px;
      min-width: 100px;
}
.vrs{
  min-height: 6em;
  max-height: 13vw;
  width: 100%;
  background: #f2f4f2;
  resize: none;
  border-radius: 6px;
  min-width: 100px;
}
.Appscroll{
  overflow-x: scroll;
}
.graphblock {
  text-align: left;
}

.progressBar_chart{
  margin: 30px 0 0 0;
}
.card{
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff;
}
.progress_total {
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.53);
  color: var(--text);
  height: fit-content;
  padding: 5px 10px;
  width: 85%;
}
.progressBar_chart .progress-bar{
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  height: 40px;
  overflow: inherit;
}
.progress_total .os_name_lice{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 13px;
}
.progress_total .os_total_lice{
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
}
.progressBar_chart .progress_bars {
  border-radius: 3px;
  height: 30px;
  font-size: 13px;
  margin-top: 10px;
  position: absolute;
  padding: 5px;
  transition: width 2s;
  width: 100%;
  border: 1px solid;
  // cursor: pointer;
  //background-image: linear-gradient(to right, #98dfff, #03a9f4);
  background: linear-gradient(90deg,rgb(38 166 91) 0%, rgb(246 255 0) 52%,  rgb(252 18 10) 100%);
}
.progressBar_chart .progress_cover {
  left: 0;
  // color: #ffff;
  font-weight: 500;
}
.progressBar_chart .progress_remain{
  background:#fa8b72;
  right: 0;
  font-weight: 900;
}
.progressBar_chart_box{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.chartHolder .projectcreation1 {
  height: 350px;
}

.graph-des-nav li span.light-green {
  background-color: #abff98;
}
.graph-des-nav li span.dark-green {
  background-color: #26a65b;
}
.graph-des-nav li span.dark-red {
  background-color: #fc120a;
}

.tooltip_progress {
  visibility: visible;
  min-width: 55px;
  box-shadow: 6px 6px 10px #b8b8b8, -5px -5px 10px #ffffff;
  position: absolute;
  left: 0%;
  transform: translateX(-50%);
  top: -34px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}
.tooltip_progress.color_1{
  border: 1px solid #fc2009;
}
.tooltip_progress.color_2{
  border: 1px solid #f8a503;
}
.tooltip_progress.color_3{
  border: 1px solid #f6f000;
}
.tooltip_progress.color_4{
  border: 1px solid #2ca958;
}
// .progress_total.blue{
//   background-color: #03a9f487;
// }
// .progress_total.green{
//   background: rgb(249 46 152 / 64%);
// }
// .progress_total.orange{
//   background: #f5b636c2;
// }
// .progress_total.violet{
//   background: #c336f591;
// }
.tooltip_progress.color_1::after{
  border-color: #fc2009 transparent transparent transparent;
}
.tooltip_progress.color_2::after{
  border-color: #f8a503 transparent transparent transparent;
}
.tooltip_progress.color_3::after{
  border-color: #f6f000 transparent transparent transparent;
}
.tooltip_progress.color_4::after{
  border-color: #2ca958 transparent transparent transparent;
}
.tooltip_progress::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 30%;
  transform: translateX(50%);
  border-width: 5px;
  border-style: solid;
}
.formColRiskUser {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 269px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.formColRisk {
  height: auto;
  width: 0;
  position: absolute;
  z-index: 1;
  // top: 165px;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.browserButton{
  padding: 7px 14px;
  background: var(--primary);
  color: white;
  border-radius: 6px;
  cursor: pointer;
  margin: 0px 6px;
}
.questionStyle{
  border-bottom: 3px solid var(--primary);
  padding: 0 0 4px;
  // width: 50%;
  font-weight: 800;
}
.axis-Label {
  color: black;
  font-family: sans-serif;
  font-size: 14px;
}

.riskAssessment{
  width: 40vh;
  height: 0vh;
}
.reviewer-tag{
  background: #80808047;
  padding: 6px;
  margin: 0px 8px;
  border-radius: 7px;
}
.remove-button{
  border: 0;
  background: none;
  padding: 0px 5px 0px 15px;
  font-size: 13px;
}

.outline-button1{
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 6px;
  width: 100px;
  font-size: 12px;
     
    padding: 8px 15px;
}
.custom_singleStrik_legend a.active{
  text-decoration: line-through;
}
.margin2{
  margin-bottom: 10px;
}


#popupdata{
    width: 100%;
    border-collapse: collapse;
    border-color:inherit;
 }
 #popupdata td,
 #popupdata th{
  text-align: center;
  border: 1px solid #ddd;
  padding: 8px;
 }
.nowrap {
  white-space: nowrap;
}
.modal-body2{
  // margin-bottom: -25px !important;
  font-size: 19px !important;
}

.modalprojectemty {
  background: white !important;
  color: var(--primary) !important;
  text-align: left !important;
  position: relative !important;
  text-align: center !important;
  padding: 12px !important;
  height: 50px !important;
  top: 0px !important;
  bottom: 10px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  border: 2px solid #F2F4F2 !important;
}
.legend-container {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: default;
    // float: right;
  }

  .uat-box {
    width: 15px;
    height: 15px;
    background-color: #42a0ff; /* Specify your desired color for UAT */
    margin-right: 5px;
    border-radius: 50%;
  }

  .sit-box {
    width: 15px;
    height: 15px;
    background-color: #6c5f89; /* Specify your desired color for SIT */
    margin-right: 5px;
    border-radius: 50%;
  }
  .Vmlihead{
    min-width: 30px !important;
    text-transform: none;
    text-transform: capitalize !important;
  }

  .Svalue_drill {
    font-weight: normal !important;
  }
  .loader-chat-mainchat{
    width: 100%;
    margin-top: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .loader-chat,
.loader-chat:before,
.loader-chat:after {
  background: var(--primary);
  -webkit-animation: load-bar-an 1s infinite ease-in-out;
  animation: load-bar-an 1s infinite ease-in-out;
  width: 0.66em;
  height: 0.66em;
}

.loader-chat {
  color: var(--primary);
  text-indent: -9999em;
  margin: auto auto;
  position: absolute;
  font-size: 11px;
  float:right;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: 50px;
}

.loader-chat:before,
.loader-chat:after {
  position: absolute;
  top: 0;
  content: "";
}
.text-right{
  text-align: right;
}
.loader-chat:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-chat:after {
  left: 1em;
}

@-webkit-keyframes load-chat-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}

@keyframes load-chat-an {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }

  40% {
    box-shadow: 0 -1em;
    height: 2.6em;
  }
}
.shfChat{
  text-align-last: right;
  position: absolute;
  right: 15px;
}
.tableShfdiv{
  width: 90%;
  margin-left: -20px;
}
.newpenv1{
  background-color: #42a0ff;
  height: 25px;
  width: 60px;
  border-radius: 20px;
  font-size: 12px;
   
  color: var(--text);
  padding: 8px 12px;
  display: flex;
  margin: 5px;
  justify-content: center;
  align-items: center;
}
.newpenv2{
  background-color: #6c5f89;
  height: 25px;
  width: 60px;
  border-radius: 20px;
  font-size: 12px;
   
  color: var(--text);
  padding: 8px 12px;
  display: flex;
  margin: 5px;
  justify-content: center;
  align-items: center;
}
#colornpenv{
  color: whitesmoke;
}
.fs-14{
  font-size: 14px;
}
.flowpredi-des .vulnerablityButtoninvisible{
  display: none;
}

.usermargin{
  margin-bottom: 40px;
}
.li-Circle{
  font-size: 5px;
  display: inline-flex;
  vertical-align: middle;
}

.Home-sm-title{
    font-size: small;
    font-weight: bold;
}
.borderBottom{
  border-bottom: 1px solid #E4E4E4;
}
.fltr-category li a.darkBlue_btn{
  color: #147ad6;
  background: none;
}
.fltr-category li a.darkBlue_btn.selected,.fltr-category li a.darkBlue_btn:hover{
  border-color: #147ad6;
  color: #147ad6;
  background: none;
}
.fltr-category li a.lightBlue_btn{
  color: #79d2de;
  background: none;
}
.fltr-category li a.lightBlue_btn.selected,.fltr-category li a.lightBlue_btn:hover{
  border-color: #79d2de;
  color: #79d2de;
  background: none;
}
.fltr-category li a.red_btn{
  color: #ec6666;
  background: none;
}
.fltr-category li a.red_btn.selected,.fltr-category li a.red_btn:hover{
  border-color: #ec6666;
  color: #ec6666;
  background: none;
}
.fltr-category li a.violet_btn{
  color: #af8beb;
  background: none;
}
.fltr-category li a.violet_btn.selected,.fltr-category li a.violet_btn:hover{
  border-color: #af8beb;
  color: #af8beb;
  background: none;
}
.fltr-category ul li a.selected{
  background: none;
}
.OS_chart{
  min-height: 350px;
  max-height: 30vw; //Height of Current chart in LM dashboard
  overflow-x: hidden;
  overflow-y: auto;
}
.OS_mainchart{
  // overflow-x: hidden;
  // overflow-y: auto;
  height: 280px;
}
a.solid-btn.greenBtnStyle.alert_mail.disabled {
  //pointer-events: none;
  cursor: default;
  background-color: #c1cca4;
}
.page-link:focus {
  z-index: 3;
  color: #ffffff;
  background-color: var(--primary);
}
.Plugin-Bold{
  font-size: 18px;
  //  
  font-weight: bold;
  color: var(--content-color);
  // text-transform: uppercase;
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 30px;
}
.display-contents {
  display: contents;
  text-transform: none !important;
  font-size: 16px;
  span {
    text-transform: capitalize !important;
    font-size: 16px;
  }
}
.display-content {
  text-transform: none !important;
  font-size: 16px;
  span {
    text-transform: capitalize !important;
    font-size: 16px;

  }
}
.pluginalign{
  text-align:left;
  vertical-align:top;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;

}
.pluginDetails {
  .Plugin-Bold {
    font-size: 18px;
    margin-right: 25px;
    font-weight: bold;
    .display-contents {
      text-transform: none !important;
      font-size: 16px;
      margin-left: 20px;
      span {
        text-transform: capitalize !important;
        font-size: 16px;
        margin-right: 20px;
      }
    }
  }
}
.status_box.low{
  border-radius: 9px;
  border: 2px solid #ff471a;
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}
.status_box.medium{
  border-radius: 9px;
  border: 2px solid #ffbf00;
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}
.status_box.high{
  border-radius: 9px;
  border: 2px solid #26a65b;
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}
.info-box p {
  font-weight: normal;
}
.delete-h{
  font-size: 18px !important;
}
.topWidth{
  width: 50px;
}
.bg-accordionGrey{
  background-color: rgb(128 128 128 / 13%);;
}
.topWidth:disabled {
  background-color: #e9ecef00!important;
  padding: 0px 0px 1px 10px;
}
.first-user {
  border: 2px solid  rgb(26, 6, 126);
}
.second-user {
  border: 2px solid  rgb(26, 6, 126);
}
.textCImag{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cmdbImg{
  border:1px solid var(--primary);;
  background: var(--primary);
  color: white;
}
.cmdbImg:hover{
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);

}
.cmdbImgIcon{
  font-size: 12px;
  margin: 1px 1px 5px 2px;
}
.cmdbImgIcon:hover
  {
    background: var(--primary);
    color: white;
}
.configGear{
  font-size: 20px;
  color:var(--primary);
  margin: 22px 0px 0px 0px;
}
.customPosition{
float: right;
position: relative;
margin: -51px 0px 0px 0px;
}
.customPosition:disabled{
  background-color: var(--primary);
  color: var(--primary-light) !important;
  border: none;
}
.reviewerSelect{
  height: 40px;
  width: 100%;
}
.reviewerSelect:focus{
  // border:1px solid grey;
  height: 40px;
  width: 87%;
}
// .customUserInput .custom-select div.value-container{
//   position: relative;
//     width: 0%;
//     text-align: left;
//     font-size: 13px !important;
     
//     font-weight: var(--regular);
//     font-style: normal;
//     color: var(--text);
//     border-radius: 5px;
//     -webkit-border-radius: 5px;
//     background-color: var(--white);
//     padding: 0px 48px 0px 4px !important;
//     outline: none;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// .customUserInput .css-1xc3v61-indicatorContainer {
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-transition: color 150ms;
//   transition: color 150ms;
//   color: hsl(0, 0%, 80%);
//   padding: 0px 5px;
//   box-sizing: border-box;
// }
// .customUserInput .css-13cymwt-control {
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   cursor: default;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-flex-wrap: wrap;
//   -webkit-flex-wrap: wrap;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   -webkit-box-pack: justify;
//   -webkit-justify-content: space-between;
//   justify-content: space-between;
//   min-height: 25px;
//   outline: 0!important;
//   position: relative;
//   -webkit-transition: all 100ms;
//   transition: all 100ms;
//   background-color: hsl(0, 0%, 100%);
//   border-color: hsl(0, 0%, 80%);
//   border-radius: 4px;
//   border-style: solid;
//   border-width: 1px;
//   box-sizing: border-box;
// }

// .customUserInput .custInputChange {
//   border-radius: 6px 0px 0px 6px;
//   height: 25px;
//   padding: 0px 0px 0px 4px;
//   // margin: 0px 0px 0px 19px;
// }

// .customUserInput .custom-select .control  {
//   border-radius: 0px 6px 6px 0px;
//   position: absolute;
//   margin: -25px 0px 0px 53px;
//   width: 94px;
// }
.customUserInput span  {
font-size: 14px;
// float: right;
}
.customUserInput {
  margin: 1rem 0;
}
.customUserInput_head{
  text-align: end;
  display: grid;
  align-items: center;
  padding-right: 20px;
}
.cmdbUserList {
  background-color: #EAEAEA;
  color: var(--text);
  font-size: 12px;
  border-radius: 4px 0px 0px 4px;
  padding: 6px;
  border: 2px solid var(--primary);
  text-align: left;
  background: transparent;
}

.cmdbUserListPrimary {
  border-radius: 0px 8px 8px 0px;
  font-size: 12px;
  border: 2px solid var(--primary);
  padding: 6px 6px 6px 2px;
  background-color: var(--primary);
  color: white;
}

.cmdbUserListButton {
  background-color: white;
  color: black;
  cursor: pointer;
  font-size: 12px;
  border: 2px solid var(--primary);
  padding: 6px;
  border-left: 1px solid;
  border-radius: 0px 8px 8px 0px;
}

.editUserAcessReviewer{
  text-align: left;
  margin-bottom: 19px;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  padding-bottom: 4px;
  border-bottom: 1px solid #ddd;
  border: 2px solid var(--primary);
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}
.radiobtn-cloudaccts{
display: -webkit-inline-flex;
align-items: center;
}
.blueViolet{
  background-color: var(--primary);
}
.userStatus-badge {
  display: inline-block;
  width: 100px;
  border-radius: 3px;
  text-align: center;
}
td.tdico.sideBorder {
  border: 1px solid #d5d9dc!important;
    border-right: none!important;
    border-bottom: none!important;
    border-top: none!important;
}
.reviewerClass input.rbt-input-main.form-control.rbt-input {
  padding: 0px 24px;
}
.appConfigAdd input.rbt-input-main.form-control.rbt-input {
  padding:21px 0px 19px 7px !important;
}

.reviewTableCustom .grid-table table tr td {
  font-size: 13px;
  color: var(--text);
  font-weight: var(--regular);
  padding: 0px 0px 0px 26px;
  border: 1px solid #dee2e6;
}

.reviewTableCustom .grid-table table .thead th {
  font-size: 13px;
   
  font-weight: var(--bold);
  color: var(--text);
  padding: 15px 20px 17px;
  border: 1px solid #dee2e6;
}
.header-w-btn{
  display: flex;
}
.header-w-btn h4{
  flex:1;
}
// .customApplicationBorder {
//   border-bottom-style: hidden !important;
//   padding: 30px 20px 0px !important;
//   text-align: center;
// }
.textcvss {
  cursor: pointer;
  text-decoration: underline;
  color: #0d6efd;
}

.fullWidth {
  width: 100%;
}

.compliantbtn{
  background-color:#a5cd39;
  color: white;
  border: none;
}

.partiallyComplaint{
  background-color:#ffaf75;
  color: white;
  border: none;
}

.nonCompliant{
  background-color:#ff6060;
  color: white;
  border: none;
}

.notApplicable{
  background-color:#b0b0b0;
  color: white;
  border: none;
}
.btnWhite{
  background-color: white;
  color: var(--primary);
}
.editUserReviewDay{
  border-radius: 0px 6px 6px 0px;
  position: absolute;
  margin: -25px 0px 0px 53px;
  width: 93px;
  border: 1px solid #b1bc93;
  padding: 1px 5px;
}
.navigateStyle {
  cursor: pointer;
}
.tooltip-topGreen > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 3px solid #27CA40;
  background-color: #27CA40;
  font-size: var(--content-size);
}
.tooltip-topYellow > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 3px solid #FFC130;
  background-color: #FFC130;
  font-size: var(--content-size);
}
.tooltip-topRed > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 3px solid #E14942;
  background-color: #E14942;
  font-size: var(--content-size);
}
.tooltip-topGreenTable > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 1px solid #27CA40;
  background-color: #27CA40;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  width: auto;
  border-radius: 3px;
}
.tooltip-topYellowTable > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 1px solid #FFC130;
  background-color: #FFC130;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  width: auto;
  border-radius: 3px;
}
.tooltip-topRedTable > .tooltip-inner {
  background-color: #fff;
  color: var(--content-color);
  border: 1px solid #E14942;
  background-color: #E14942;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  width: auto;
  border-radius: 3px;
}
.tooltip-topGreenTable > .tooltip-arrow::before{
  border-color:#27CA40 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-topYellowTable > .tooltip-arrow::before{
  border-color:  #FFC130 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-topRedTable > .tooltip-arrow::before{
  border-color:  #E14942 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-medium > .tooltip-inner {
  background-color:#f5e97f;
  color: var(--content-color);
  border: 1px solid #ffe500;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-Info > .tooltip-inner {
  background-color:#DEEBF7;
  color: var(--content-color);
  border: 1px solid #a7cbe3;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-High > .tooltip-inner {
  background-color:#ffdc9c;
  color: var(--content-color);
  border: 1px solid #ffa500;
  font-size: 0.70em;
  font-weight: 700;
  width: auto;
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-notAvailable > .tooltip-inner {
  background-color:#c5d6fd;
  color: var(--content-color);
  border: 1px solid #95b0f0;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-Critical > .tooltip-inner {
  background-color:#ff7575;
  color: var(--content-color);
  border: 1px solid  #f00;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-Low > .tooltip-inner {
  background-color:#aaf9ff;
  color: var(--content-color);
  border: 1px solid  #00ecff;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;  
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-medium > .tooltip-arrow::before{
  border-color: #d4ba00 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-Info > .tooltip-arrow::before{
  border-color: #DEEBF7 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-High > .tooltip-arrow::before{
  border-color: #e58500 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-Critical > .tooltip-arrow::before{
  border-color:  #cc0000 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.tooltip-Low > .tooltip-arrow::before{
  border-color:  #00b8cc transparent transparent transparent !important;
  margin-left: -5px !important;
}
.gray-panel{
  background: var(--gray8);
    margin: 20px 0 0;
    padding: 10px 20px;
    border-radius: 5px;
}
// .openClose-panel{
//   max-height: 150px;
//   overflow: hidden;
//   transition: max-height 0.5s ease-out;
// }
// .openClose-panel.active{
//   max-height: 500px;
// }
// .openClose-panel .form{
//   opacity: 0;
//   visibility: hidden;
//   max-height: 0;
//   //overflow: hidden;
//   transition: opacity 0.5s ease-out, visibility 0.5s ease-out, max-height 0.5s ease-out;
// }
// .openClose-panel.active .form{
//   opacity: 1;
//   visibility: visible;
//   max-height: 500px;
//   transition: opacity 0.5s ease-out, visibility 0.5s ease-out, max-height 0.5s ease-out;
// }
.btn-container{
  display: flex;
  justify-content: flex-end;
}
.btn-container button {
    // margin: 5px 8px;
}

.btn-container button:last-child {
    margin-right: 0;
}
.modalSubhead{
  padding: 0 5px;
  font-weight: bold;
}

.iconStyle {
  color: var(--primary);
   font-size: 14px;
   margin-top: 4px;
}
.iconStyleCloud {
  color: var(--primary);
  font-size: 18px;
  margin-top: 2px;
}
.faInfoCircleStyle {
  color: var(--primary)
}

.custom_scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #c1c1c1 0%, #c1c1c1 100%);
}
  .custom_scroll::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(180deg, #c1c1c1 0%, #c1c1c1 100%);

  }
.pagination {
  --bs-pagination-color: #102250 !important;
  --bs-pagination-font-size: 0.8rem;
}
.currentPage nav_1 {
  font-size: 13px;
}

//login page
.loginPage {
  color: #6e6b7b;
  // height: 100vh;
  align-items: center;
  background: white;
  // background-color: var(--);
  // padding-top: 30vh;

  .loginPageContent {
    background-color: #FFFFFF;
    // height: 100vh;
    color: #fff;
    padding-top: 15vh;
    padding: 0vh 30px 0vh 30px;
  }
  .loginTAEContent{
    margin: 3px 7px;
  }
  .loginTAEContentText{
    color: var(--primary);
    font-size: 12px;
    font-weight: 500;
  }
  #TAE_logo{
    width: 18px;
    height: 18px;
    margin: 0px 1px 0px 1px;
  }
  .contentTitle {
    font-size: 16px;
  }
  .contentStyle {
    padding: 50px 90px 50px 75px;
    text-align: justify;
    // margin-top: 15vh;
    h3 {
      padding-bottom: 20px;
    }
  }
  .loginForm {
    // padding-top: 15vh;
    background-color: var(--primary);
    .form-check {
      text-align: left;
      padding-bottom: 20px;
      margin-left: 1.5vw;
    }
  }
  .contentTitle {
    font-weight: bold;
    margin-top: 20px;
    font-size: 30px;
  }
  .loginTitle { 
    color: #FFFFFF !important;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  #loginBtn{
    background-color: #FFFFFF;
    color: var(--primary);
  }
  #loginBtn:disabled{
    background-color: #d6d5d5;
    color: var(--primary);
    pointer-events: none;
  }
  #loginBtn:hover{
    background-color: #d6d5d5;
  }
  .login_RememberMe{
    color: #FFFFFF !important;
  }
  #login_logo{
    width: 400px;
  }
  .frmgroup {
    padding: 18px;
    width: 30vw;
    // margin-left: 7vw;
    margin-bottom: 0px;
  }
  .contentPara {
    margin-bottom: 1.2rem;
  }
  .contentParaTitle {
    margin-bottom: 0.4rem;
  }
  .loginBtn {
    margin-left: 20vw;
  }
}
.top3List {
  font-weight: normal;
}
.flowhead {
  .p-0 {
    font-size: 18px;
    font-weight: normal;
  }
}

.footer {
  color: var(--primary);
  font-size: var(--content-size);
  text-align: right;
  padding-right: 20px;
  background-color: var(--lightbg);
}

.cveIdStyle {
  font-weight: normal !important;
}
.cvssScoreStyle {
  text-transform: none;
}
.pagination_rowPerPage{
  color: var(--primary);
  // font-size: 1rem;
  font-size: 0.8rem;
}
.custom-select div.value-container{
  padding: 5px 10px 5px 10px !important;
}
.grid-table .solid-btn{
  padding: 8px 20px;
}

.appConfigAddNew {
  .default-input input {
    // border: 1px solid var(--border);
    font-size: var(--content-size);
    padding: 19px 0px 17px 7px;   }
  .form-control {
    font-size: var(--content-size);
    padding: 7px 0px 10px 7px; 
  }
  .dropdown-item {
    font-size: var(--content-size) !important;
  }
}
.paddingBusiness{
  .default-input input {
    padding: 15px 6px 19px 7px;
  }
}
.paddingLicense{
  .rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    margin-top: -1px;
    overflow: hidden;
    // overflow-y: auto; /* Enable vertical scrollbar when content exceeds height */
    // height: 50px;
}
}
.homeSelectedApp {
  background-color: var(--primary) !important;
  color: #FFFFFF;
}
.btnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);
}
.rolemappingbutton .btnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
  // margin-left: 10px;
  height: 40px;
  margin-bottom: 2px;
  border: 1px solid var(--button-bg-color);
}
//common button styles
.btnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);
}
.btnStyle:hover {
  background-color: var(--button-hover-bg-color) !important;
  color: var(--button-hover-text-color);
  border: 1px solid bar(--button-bg-color);
}
.btnStyle:disabled {
  cursor: not-allowed;
}

.groupButtons {
  margin-left: 60vw;
  width: 250px;
}
.cancelBtnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: #606060 !important;
  color: #FFFFFF !important;
  border: 1px solid #606060 !important;
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
}
.cancelBtnStyle:hover {
  background-color: #FFFFFF !important;
  color: #606060 !important;
  border: 1px solid #606060;
}
.greenBtnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--secondary) !important;
  color: #42560a !important;
  border: 1px solid var(--secondary) !important;
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
}
.greenBtnStyle:hover {
  background-color: #FFFFFF !important;
  color: #42560a !important;
  border: 1px solid #42560a;
}
.addGroup {
  background-color: var(--primary);
  color: #FFFFFF
}
.addGroup:hover, .addGroup:active {
  background-color: #FFFFFF;
  color: var(--primary);
}
.serverInput input.form-control {
  border: 1px solid var(--secondary-light)
}
.form-control {
  font-size: 13px;
  border: 1px solid var(--primary-active);
}

//
.mini-model-panal{
  background-color: #ededed;
  padding: 15px 0px 5px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.mini-model-table{
  width: 100%;
}
.mini-pointer{
  cursor: pointer;
}
// .custom_scroll{
//   max-height: 500px;
//   overflow-y: auto;
// }
// @media (max-height: 639px) {
//   .custom_scroll{
//     max-height: 400px !important;
//     overflow-y: auto;
//   }
// }
// @media (max-height: 852px) {
//   .custom_scroll{
//     max-height: 600px !important;
//     overflow-y: auto;
//   }
// }
// @media (max-height:  1278px) {
//   .custom_scroll{
//     max-height: 90vh !important;  
//     // overflow-y: auto;
//   }
// }
// @media (min-height:  1917px) {
//   .custom_scroll{
//     max-height: 90vh !important; 
//     // overflow-y: auto;
//   }
// }

.navChildScrollHeight{
  max-height: 180px;
}
.custom_scroll_Common{

  overflow-y: auto;
}
.custom_scroll_Common::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
.custom_scroll_Common::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom_scroll_Common::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 10px;
}
.custom_scroll_Common::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cmdbPage .custom_scroll {
  max-height: 70vh;
}
.table-responsive.custom_scroll{
  max-height: 70vh;
}
// @media (min-height: 798px) and (max-height: 958px) {
//   .cmdbPage .custom_scroll {
//     max-height: 65vh;
//   }
// }
@media (min-height: 958px) and (max-height: 1278px) {
  .cmdbPage .custom_scroll {
    max-height: 75vh;
  }
}
@media (min-height: 1278px) and (max-height: 1917px) {
  .table-responsive.custom_scroll {
    max-height: 85vh;
  }
}
@media (min-height: 1917px)  {
  .table-responsive.custom_scroll{
    max-height: 90vh;
  }
}
// @media (min-height: 798px) and (max-height: 958px) {
//   .table-height {
//     padding:22px 20px !important;
//   }
// }
// @media (min-height: 958px) and (max-height: 1278px) {
//   .table-height {
//     padding:29px 20px !important;
//   }
// }
// @media (min-height: 1278px) and (max-height: 2558px) {
//   .table-height {
//     padding:43px 20px !important;
//   }
// }
.th-serverTable-border{
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.serverTable thead{
  box-shadow:0px 0px 0px 2px rgb(229, 229, 229) !important;
}
.tablehidden{
  border-top: hidden !important;
}
// .mx-3.float-right .value-container {
//   padding: 5px 35px 5px 15px !important;
//   width: auto;
// }
.inputGroup.float-right .value-container {
  padding: 5px 35px 5px 15px !important;
  width: auto;
}
.float-start.mb-3.serverBtn select.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  width: 73px !important;
}

.mini-model-table tbody tr{
  border: solid 5px #ededed;
}
.mini-model-table td{
 padding: 10px;
 background-color: #ffffff;
 word-break: break-word;
 border: 5px solid #ededed;
}
.mini-model-table th{
  padding: 5px 10px !important;
  border: 5px solid #ededed;
    background: white;
}
 .mini-model-table td:last-child{
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
 }
 .mini-model-table td:first-child{
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
 }
 .temMeter-img{
  width: 8px !important;
 }

.trmgTable .custom_scroll{
max-height: 55vh!important;
}
.circular09Table .custom_scroll {
  max-height: 65vh !important;
}

@media (min-height: 958px) {
  .circular09Table .custom_scroll{
    max-height:75vh !important;
  }
}
// @media (min-height: 958px) {
//   .circular09Table .custom_scroll{
//     max-height:70vh !important;
//   }
// }


// .trmgFulltableClass .custom_scroll {
//   max-height: 90vh !important;
//   overflow-y: auto;
// }

.statusFilter .custom-select.css-b62m3t-container {
  width: 208px;
}
/* Add this CSS to your stylesheet */
.custom_table_vul thead {
  position: inherit;
  top: 0;
  background-color: #fff; /* You can change the background color to match your design */
  z-index: 1;
  box-shadow: 0px -1px 2px 0px rgb(229 229 229);
}
.actionIcon{
  display:grid;
  justify-content: center;
}
.serverInput button.btn {
  border:1px solid var(--secondary-light)
}
.text-business{
  text-wrap: balance;
}

.homePageNave .m-nav {
  position: relative;
  width: inherit;
  margin: 3px 18px 0;
  padding: 3px;
}

.homePageNave .dashTopNav .nav_1 {
  padding: 10px 15px;
  background-color: #e8e8e8;
  white-space: nowrap;
  height: 39px;
  margin: 1px 0px;
  border-radius: 5px;
}


.count_wid{
  width: 72px;
  }

  .sl_no_wid{
  width: 22px;
  }

  .textAreaTextManage {
    border: 1px solid;
    padding: 5px;
    border-radius: 0px;
    //position: absolute;
    margin: -5px 0px 0px 0px;
    background-color: #f2f4f2;
    position: relative;
    z-index: 1; 
  }

  .search {
    margin-bottom: 16px;
    // .custom-select {
    //   width: 190px !important;
    //   margin-right: 5px !important;
    // }

  // .serverBtn {
  //   width: 100%;
  //   margin-bottom: 10px !important;
    .rowsPerPage {
      float: left;
      width: 50%;
    }

    .search {
      float: right;
      margin-right: 5px;
      width: 20%;
      .searchIconStyle {
        border: 1px solid #000;
      }
    }
  }

  .rowsPerPage {
    float: left;
    // width: 40%;
  }

  .manageVulStyle {
    float: right;
    margin-right: 5px;
    // width: 16%;
  }

  .searchStyle {
    width: 190px;
    margin-right: 5px;
    float: right;
    font-size: var(--font13);
  }


  .tableFilterStyle {
    border: 1px solid black;
    height: 50px;
  }
  .checkStyle {
    height: 43px;
  }

  .tableStyle {
    .custom-select {
      margin-right: 5px;
      // margin-bottom: 15px;
    }
  }
  .modal-dialog-centered .modal-body2{
    width: 95%;
  }
  .userCRF{
    font-size: 14px;
  }

  .userCRPF{
    font-size: 16px!important;
  }
  .trmgDD3DF{
    font-size: 15px!important;
  }
  .lastScanPadding {
    padding: 10px 18px !important;
  }

  .Tab_btn{
    color: var(--content-title-color);
    background-color: #e8e8e8;
    border-radius: 5px;
    padding: 10px;
    border: none;
    margin-right: 10px;
    color: var(--content-title-color);
    cursor: default;
}
.Tab_btn.active{
    background-color: var(--primary-active);
    border-radius: 5px;
    color: #fff;
    cursor: default;
}
.customTab{
  padding: 0 5px;
}
.customTab .nav-item:last-child{
  margin: 0;
}
.Height-email input{
  width: 100%;
}
#Description.Mail_message{
  max-height:16.5vw;
}
.mail_message{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 
}
.email_tab .customTab{
 margin-top: 1.3rem;
}
.config_cover{
  padding: 0 5px;
}
.errorModal .modal-content{
  color: #d70000;
  border: 2px solid #d70000;
}
// .singel_btn_cover button{
//   margin: 0;
// }
.businesCofgTabAlign{
  padding: 10px 20px !important;
}
.businessOverlap {
  padding-right: 30px !important;
}
.appConfoSettings {
  border-color: darkblue;
  width: 93px;
  margin: -25px 0px 8px 56px;
}
.appConfoSpan{
  margin: 0px -9px;
}
.licenseDetailName{
  width: 220px !important;
}


#dilldown2_littel_tablwe{
  text-wrap: nowrap;
}
.assetSave{
  padding: 0px 0px 1px 0px !important;
}

.graphScrollTreeMap{
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.errorAlighn{
  white-space: nowrap;
}
.checkBox{
  margin: 64px 0px 0px 5px !important;
}

div.labelHidden label {
  visibility: hidden;
  height: 20px;
}
.filepath {
  font-size: 12px;
}
.appConfigAddinput input {
  // padding: 20px 0px 20px 7px;
  border: 1px solid var(--primary-active);

}
.triggerScan {
  margin-right: 10px !important;
}

.projectSave{
  margin: 0px 5px 0px 0px !important;
}
.projectSaveCloud{
  margin: 0px 12px 0px 0px !important;

}
.inputBOXOverlapping{
  padding: 15px 0px 19px 7px;
}
.reviewerSelect input{
  height: 100%;
}
.btn-Height{
  min-height: 38px;
}
.theadBalance{
text-wrap: balance;
}
.pluginTable th {
  white-space: nowrap;
}
.errorCheck{
  margin-left: 50px;
  // margin-top: -16px
}
.accessReviewerLoader {
  border-radius: 6px;
  height: 100%;
  padding: 60px;
  text-align: -webkit-center!important;
  position: relative;
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}
.detailsFilterStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
   margin-right: 5px;
  height: 38px;
  float: right;
  // margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);
  &:hover {
    color: var(--button-bg-color);
    background-color: var(--button-text-color) !important;
  }
}
.wrap_class{
  white-space: pre-wrap;
    max-width: 200px;
}
.informationIconStyle svg {
  font-Size: 20px !important;
}
.smail_error_icon.informationIconStyle svg {
  font-Size: 14px !important;
}
.informationIconStyle path {
  stroke: red  !important;
}

.iconStyle {
  position: relative;
}
.serverInfoMargin{
  height:-1px;
}
// .serverInfoMargin  .css-qyzydv-control{

//   border:1px solid #d6d9dc;
// }
.customDropdownTypeHead{
  float: right;
    color: #d70000;
    font-size: 10px;
}

.liscLabel .rbt-input {
  font-size: 11px !important;
  padding: 9px !important;
}
.errorlabel{
  margin-top: -16px;
}
.TypeHeadBorder .css-qyzydv-control{

  border:1px solid #d70000;
}
.AppConfigTooltip {
  padding: 0px;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.AppConfigTooltip .AppConfigtooltiptext {
  visibility: hidden;
  background-color:  #E14942;
  color: #ffffff;
  // text-align: center;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}
.AppConfigTooltip .AppConfigtooltiptextEven {
  visibility: hidden;
  background-color:  #E14942;
  color: #ffffff;
  // text-align: center;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: -26%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.AppConfigTooltip:hover .AppConfigtooltiptext {
  visibility: visible;
  opacity: 1;
}
.AppConfigTooltip:hover .AppConfigtooltiptextEven {
  visibility: visible;
  opacity: 1;
}

.backButton {
  margin: 5px 8px 5px 4px !important;
  height: 40px;
  min-width: 93px;

}
.srvrTableFilters{
  width: 200px;
  margin-right: 5px;
}
.licensebutton{
  opacity: 0.8;
}
.setinput{
  border-radius: 10px 0px 0px 10px;
}
.DaysColour{
  border: 1px solid var(--primary-active);
  background-color: #e9ecef !important;
  border-radius: 0px 10px 10px 0px;
  padding: 5px;
}
.Height-email{
  height: 39px;
}
.dateError {
  border: 1px solid #d70000 !important;
}
.log_cofig{
  width: 60px;
  position: relative;
  top: 10px;
  right: 5px;
}
.logoTooltip {
  .tooltip-inner {
    background-color: var(--primary) !important;
    width: 80% !important;
  }
  .logoTooltipImg {
    background-color: var(--primary) !important;
    width: 100%;
    height: 100%;
  }
  .tooltip-arrow {
    display: none;
  }
}

.TagTooltip .tooltip-inner {
  background-color: #fff;
  border: 1px solid #131C47;
  color: #131C47;
  font-weight: bold;
}
.TagTooltip.untagged .tooltip-inner {
  background-color: #fff;
  border: 1px solid #9E7D26;
  color: #9E7D26;
  font-weight: bold;
}
.TagTooltip .tooltip-arrow {
  color: #131C47;
}
.TagTooltip.untagged .tooltip-arrow {
  color: #9E7D26;
}
.TagTooltip.tooltip.show{
  opacity: 1 !important;
}
.accesswrap{
  text-wrap: nowrap;
}
.appmargin{
  margin-top: 4px;
}

.assetDD_container{
  padding: 20px 0;
}

.assetDD_panel{
  // margin-bottom: 20px;
}
.assetDD_panel .pug-1{
  font-size: 25px;
  font-weight: 700;
  padding-right: 10px;
}
.assetDD_panel .pug-2{
  font-size: 18px;
  font-weight: 500;
  // display: grid;
  // align-items: center;
}
.App_distr_chart .echarts-for-react {
  height: 16vw !important;
}
.max-h20vw{
  max-height:20vw !important;
}
.max-h10vw{
  max-height:10vw !important;
}
.echarts-for-react {
    height: -webkit-fill-available !important;
}
.breadcrumbStyle {
  margin-left: 2px;
}
.breadCrumb {
  display: inline;
  // margin-right: 5px;
  cursor: default;
  // a {
  //   color: var(--primary);
  // }
  // a:hover {
  //   color: var(--primary) !important;
  //   text-decoration: underline !important;
  //   cursor: pointer;
  // }
}
.breadCrumbItem {
  margin-right: 5px;
  display: inline;
  color: #0084f4 !important;
  &:hover {
    color: #0084f4 !important;
    text-decoration: underline !important;
    text-decoration-thickness: 1px !important;
  }
}

.vulBoxes {
  margin: 2px;
   width: 100%;
  color: #000; 
  padding: 5px 0px;
  h4 {
    font-size: medium;
    margin: 3px !important;
    border-bottom: 1px solid #646464;
    padding-bottom: 5px !important;
  }
  h5{
    font-weight: 500;
    padding-bottom: 2px;
    margin: 0 !important;
  }
 p {
  font-size: 10px;
  margin-bottom: 5px;
  margin-top: 8px;
 }
}
.guagescoreChart{
  padding: 15px 0px 0px 0px !important;
}
.cloudPadding{
  padding: 9px 9px 0px 10px;
}
.paddingDevices{
  padding: 9px 9px 0px 10px;
}
.nodataICM{
  height: 289px;
}

.tablelc div.custom_scroll {
  max-height: 300px !important;
  overflow-y: auto ;
}
@media screen and (min-width: 1824px){
  .tablelc div.custom_scroll{
    max-height: 500px !important;
  }
}
.popUpScroll{
    overflow-y: auto;
    display: block;
    max-height: 450px;
  }
#UserData{
  width: 100%;
  border-collapse: collapse;
  border-color:inherit;
}
#UserData td,
#UserData th{
// text-align: center;
border: 1px solid #ddd;
padding: 8px;
}
#userDrillClick {
  cursor: pointer;
  color: #0d6efd;
  text-decoration: underline;
}
.table-container {
max-height: 180px !important;
overflow-y: auto !important;
}
.table-container::-webkit-scrollbar {
  width: 12px;
}
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-container::-webkit-scrollbar-thumb {
  background: #888;
}
.table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.emptyDiv {
  border-bottom: 0px !important;
}
.spinHeight {
  height: 100px;
}
.TypeHeadBorder .css-13cymwt-control{
  border:1px solid #d70000 !important;
}
.errorBorder{
  border:1px solid #d70000 !important;
}
.mxh-90vh{
  max-height: 90vh;
}
.mxh-70vh{
  max-height: 70vh;
}
.tooltip-tHead .tooltip-inner {
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  align-items: center; 
  justify-content: center; 
  text-align: left;
}
.mxh-65vh{
  max-height: 65vh;
}
.configureToolSetting {
  height: 40px;
  min-width: 90px;
}
.toolsButtonWrap{
  height: 40px;
  min-width: 90px;
}
.toolsSaveButton{
  height: 40px;
  min-width: 90px;
  margin-right: -6px !important;
  padding: 0px 29px;
}
.detailsCursorTooltip{
  text-align: center;
  cursor: pointer;
}.templateCursor{
  text-align: center;
}
.detailsCursorTooltip1{
  display: flex;
  align-content: space-around;
  justify-content: space-around;
}
.currentVulnerabilityForm{
  border: 1px solid var(--primary-active);
  padding: 8px 22px 9px 10px;
  border-radius: 5px;
}
.aligntopaudit{
    margin-bottom: 15px;
}
// .businessServer{
//   margin: 3px -6px 0 22px !important;
// }
.cloudAccountsAction{
  margin: 0px 19px 0px -16px !important;
}

.tooltip-severityBox > .tooltip-inner {
  color: var( --white);
  width: 100px;
  font-size: 0.70em;
  font-weight: 700;
  height: auto;
  border-radius: 3px;
  padding: 2px;
  margin: 2px;
}
.tooltip-severityBox > .tooltip-arrow::before{
  margin-left: -5px !important;
}
.toolimg{
  width: 22px;
}
// .headerorg1{
//   margin-top: 50px;
// }

.headerorg2{
  margin-top: 30px;
}
.tableHeaderTooltip {
  opacity: 1;
}
.tooltipHeader{
  opacity: 0; /* Start with opacity set to 0 */
  transition: opacity 0.3s ease;
  background-color:#ededed;
  color:var(--primary);
  margin: 0px 0px 8px 0px;
  border-radius: 5px !important;
  border: 1px var(--primary);
  .tooltip-inner {
    border: 1px solid black; /* Specify the border style */
    border-radius: 5px !important;
    background-color: #ededed;
    color: var(--primary);
  }
  
}
.tooltipHeader.show {
  opacity: 1; 
}
.tooltipHeader > .tooltip-arrow::before{
  //border: black transparent transparent transparent !important;
  margin-left: -5px !important;
  top: 98%;
}
.NodataAvailable {
  background: white !important;
  color: var(--primary) !important;
  text-align: left !important;
  position: relative !important;
  text-align: center !important;
  padding: 131px !important;
  height: 50px !important;
  top: 0px !important;
  bottom: 10px !important;
  font-size: 13px !important;
  font-weight: bold !important;
 }
 .NodataAvailableVM{
  padding:39px 12px 12px 12px !important
 }
 .NodataAvailableVMTDD{
  padding:122px 0px 132px 0px !important
 }
 .toolsActionAlign {
  margin: 3px -26px 0 -15px;
 //padding: 0 29px 0 0;
  text-align: center;
 }
.accessReviewerLoaderemail {
  border-radius: 6px;
  height: 100%;
  padding: 50px;
  text-align: -webkit-center !important;
  position: relative;
  box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}
.tooltipHeader .tooltip-inner {
  background-color: #ededed;
  color: var(--primary);
  border: black;
  opacity: 1;
}

.curser{
  cursor: pointer;
}

.accessDisabled:disabled {
  background-color: var(--primary);
  border: none;
  pointer-events: none;
}

.testbtn {
  background-color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  color: #42560a !important;
}
.testbtn:hover {
  background-color: #f5f7f5 !important;
  color: #42560a !important;
  border: 1px solid #42560a !important;
}
.cancelbtn {
  background-color: #606060 !important;
  border: 1px solid #606060 !important;
}
.cancelbtn:hover {
  background-color: #f5f7f5 !important;
  color: #42560a !important;
  border: 1px solid #42560a !important;
}
.modlControlId p{
  font-size: 14px !important;
}
.modlControlIdHead th{
  font-size: 16px !important;
}
.modlControlIdHead td {
  font-size: 16px !important;
  font-weight: 500;
}
.Bbox {
  float: left;
  height: 13px;
  width: 20px;
  clear: both;
  border-radius: 1px;
}

.Bred {
  background-color: red;
}

.Bgreen {
  background-color: green;
}

.Bgrey {
  background-color: grey;
}

.tableTreeChartLegend td {
  font-size: 10px;
}
.tableTreeChartLegend{
  margin-bottom: -48px;
}
.borderColorTarget{
  border: 1px solid var(--primary-active);
  padding: 8px 10px 9px 11px;
  border-radius: 4px;
}
.smtpConfigHeader {
  margin-bottom: 30px !important;
}
.vmPopDateInput{
  padding: 0.55rem 0.75rem !important;
  border: 1px solid var(--primary-active);
}
.groupsPadding{
  padding: 0px 31px;
}
.usersPaddingActol{
  margin: 0px -1px 0px -2px !important;
}
.configUserServers{
  margin: 3px -65px 19px 0px;
}
.userManagementPadding{
  padding: 0px 0px 0px 17px !important;
 // margin: 0px -36px;
}
.devopsManagementEnginePadding{
  padding: 0 0 0 2px;
}
.cloudAccountsManagement{
  margin: 0px -4px 0px -3px !important;
}
.SMTPspin{
  padding: 52px 214px !important;
}
.appConfigPadding{
  margin: 0px -9px 0px 11px;
}
.osLicenseConfigPadding{
  margin: 0px -6px 0px -7px;
  padding: 0px 0px 0px 28px;
}
.riskConfigPadding{
  margin: 2px -1px 8px -59px;
}
.licenseManagementFilterPadding{
  padding: 10px 22px !important;
}
.licenseManagementMonths{
  margin: 0px 0px -7px 0px !important;
}
.filterScroll{
  overflow-x: auto;
}


//scroll
.chart-container {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* width */
.chart-container::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.chart-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chart-container::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}
.stories-list {
  max-height: unset;
  overflow-y: unset;
}
.drillFontflow{
font-size: 18px !important;
} 
.fontDrillThree{
  font-weight: normal !important;
} 
//drill down scroll for flow metrics 
.flowDrill_scroll {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* width */
.flowDrill_scroll::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.flowDrill_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.flowDrill_scroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}


.clearfilterStyle {
  margin-top: 22px;
  font-size: 11px !important;
  min-width: 90px;
  font-size: var(--button-size);
  color: var(--button-text-color) !important;
  background-color: var(--pink) !important;
   margin-right: 5px;
  height: 19px;
  float: right;
  // margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);
  &:hover {
    color: var(--button-bg-color) !important;
    background-color: var(--button-text-color) !important;
  }
}
.defaultfilterStyle {
  font-size: 11px !important;
  height: 19px;
  min-width: 90px;
  font-size: var(--button-size);
  color: var(--primary-light) !important;
  background-color: var(--primary) !important;
  margin-right: -90px;
  float: right;
  margin-bottom: 20px;
  border: 1px solid var(--button-bg-color);

  &:hover {
    color: var(--button-bg-color) !important;
    background-color: var(--dark-theme-selected-opt) !important;
  }
}
.defaultfilterStyle.btn {
  padding: 0px !important;
}
.clearfilterStyle.btn {
  padding: 0px !important;
}
.clearfilterStyle.btn:disabled  {
  background-color: var(--pink) !important;
  color: var(--dark-theme-selected-opt) !important;
  border: 1px solid var(--dark-theme-selected-opt);
}
.subfilterstyle{
  display: flex;
  flex-wrap: wrap;
  width: 84%;
  margin-left: 3%;
  margin-top: 5%;
  margin-bottom: 2%;
}
.Applybtn{
  position: relative;
  margin-top: 4%;
  float: right;
}
.Applybtnclear{
  position: relative;
  margin-top: 4%;
  margin-right: 10%;
  float: right;
}
.clearButtonStyle{
  font-size: var(--button-size);
  color: var(--button-text-color) !important;
  background-color: var(--pink) !important;
}
.divisionPadding{
  padding: 0px 37px !important;
}
.drillDownLMTable{
  max-height: 300px !important;
}

.activeTools {
  height: 276px  !important;
}
.LicenseToolsDD .css-1nmdiq5-menu {
  z-index: 999;
}

.LicenseTable {
  max-height: 500px !important;
  overflow-y: auto ;
}
label.form-label{
  margin: 0;
}
@media screen and (min-width: 1824px){
  .LicenseTable{
    max-height: 500px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1422.22px) {
  .LicenseTable {
    max-height: 400px !important;
  }
}
@media screen and (min-width: 1163.64px) and (max-width: 1280px) {
  .LicenseTable {
    max-height: 300px !important;
  }
}
@media screen and (min-width: 920px) and (max-width: 1163.65px) {
  .LicenseTable {
    max-height: 250px !important;
  }
}
.trmgDrillTable {
  max-height: 400px !important;
  overflow-y: auto ;
}

@media screen and (min-width: 1824px){
  .trmgDrillTable{
    max-height: 600px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1422.22px) {
  .trmgDrillTable {
    max-height: 330px !important;
  }
}
@media screen and (min-width: 1163.64px) and (max-width: 1280px) {
  .trmgDrillTable {
    max-height: 280px !important;
  }
}
@media screen and (min-width: 920px) and (max-width: 1163.65px) {
  .trmgDrillTable {
    max-height: 125px !important;
  }
}

.userAccessInput {
  border: 1px solid var(--primary);
}

.flowVelocityDays{
  padding: 0px 15px 8px 0px;
}
.cloudimportCSV{
  // margin-left: -4px !important;
  margin-right: 10px !important
}
.invalidStyle{
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.invalidStyleNote{
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: red;
  margin-top: 10px;
}
.invalidStyle .invalidStyleAnchor {
  color: blue !important;
  text-decoration: underline !important;
}
.OkayBtnStyle{
  min-width: 90px;
  font-size: var(--button-size);
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color);
  margin-left: 10px;
  height: 40px;
  margin-bottom: 0px;
  border: 1px solid var(--button-bg-color);
}

.highlightRow {
  background-color: #ececec !important;
  font-weight: 600 !important;
}

.homePageBulletsisk{
  list-style-type: disc!important;
}

.OSlicenseInput input{
  padding: 19px 7px 19px 7px !important;
}
.OSlicenseDateInput{
  margin-bottom: 13px;
}
.OSlicenseDateInput input{
  padding: 8px 7px 8px 7px !important;
}
.discardColorButton{
  background-color: #ff7e80 !important;
  color: white !important;
  border-color: #ff7e80 !important;
  margin-bottom: 20px;
  height: 40px
}
.discardColorButton:hover{
  background-color: white !important;
  color:#ff7e80 !important;
}


.tierClass {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.divisionClass {
  margin-left: 0px;
}
.licenseClass {
  margin-left: 3px;
}
.currentLicense_Loader {
  background: #ffffff;
  z-index: 998;
  overflow: hidden;
  position: relative;
  display: grid;
  justify-items: center;
}

.currentLicense{
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

// .addLicensceDropdown .custom-select .control {
//   border: 1px solid #ced4da;
// }
// .addLicensceDropdown .custom-select .control:hover {
//   border: 1px solid #ced4da;
// }

.flowpredicTab{
  margin: 0px 4px 0px 21px;
  font-size: var(--font14);
  text-align: center;
  font-weight: var(--regular);
  display: inline-block;
  background-color: #ffffff;
  color: #000;
  border-radius: 6px 6px 0px 0px;
  padding: 8px 20px;
  border: 1px solid #b9b9b9;
  text-transform: capitalize;
  transition: all 0.4s linear;
}

.gridtitleflowMetrics{
  padding: 2px 0px 10px 0px;
  color: #102250;
}
//scroll
.flowload-container {
  overflow-y: hidden;
  overflow-x: auto;
}
/* width */
.flowload-container::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.flowload-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.flowload-container::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}
.tooltip-flowLoad > .tooltip-inner {
  background-color:#fff;
  color: var(--content-color);
  border: 1px solid #000;
  width: auto;
  font-size: 0.70em;
  font-weight: 700;
  height: auto;
  padding: 0px 10px;
  border-radius: 3px;
}
.tooltip-flowLoad > .tooltip-arrow::before{
  border-color: #000 transparent transparent transparent !important;
  margin-left: -5px !important;
}
.cancelBudget{
  font-size : 14px !important;
}

.MergeMetricsDDOption .css-1nmdiq5-menu {
  z-index: 999;
}
#myreqFilter {
 margin-top: 0px !important;
}
.drilldowngrid{
  text-wrap: balance !important;
}
.alignproject .manageVulStyle {
  margin-right: 0px  !important;
}
.flowmetrics-badge {
  display: inline-block;
  width: 125px;
  border-radius: 3px;
  text-align: center;
}
.flowLoadTitle{
  cursor: default;
}
.not-allowed{
  cursor: not-allowed;
}
.drilldownThreetitle{
  font-size: 16px;
}
.Summerypeople {
  white-space: break-spaces;
}
.projectSymbol{
  width: 20px !important;
  height: 20px !important;
}
.flowmetricsGridSN{
  width: 10px;
}
.fdchart .dashcol.bg-white {
  height: 405px !important;
}
.cisPlugun-scroll .custom_scroll{
  max-height: 55vh !important;
}
.productivityChart .flowhead {
  font-size: var(--font15);
  font-weight: var(--bold);
  color: var(--primary);
  margin-bottom: 30px;
}
.widthProductivity {
  width: 30%;
}

.followUpBtnLoading{
    width: 90px;
}

.followUpBtnLoading .spinner-border{
  width: 16px;
  height: 16px;
}
.actionplanMargin{
  margin: 0px -399px 0px -5px;
  border-radius: 0.375rem;
}
.complianceTrendHeight{
  height: 383px;
}
.removeLicUser .rbt-input {
  background-color: #e9ecef !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    color: var(--text) !important;
    padding: 5px 10px!important;
    display: inline-block !important;
    /* margin: 0px !important; */
    border: 2px solid var(--primary) !important;
    border-color:var(--bs-border-color-translucent) !important;
}

.followUpBtn:disabled {
  background-color: var(--primary);
  border: none;
  font-size: 13px;
}
.followUpBtn {
  font-size: 13px;
}

.obsDateFltrIcon {
  position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}

.obsDate .form-control {
  width: 168px;
}
.errorinclude {
  margin-top: 3px;
  float: inline-end;
}
.toolboard-error {
  border: 1px solid #d70000 !important;
}
svg.big_up_arrow {
    font-size: 14px;
    position: relative;
    bottom: 2px;
    color: #2d2b3e;
    cursor: default;
}
svg.small_up_arrow {
  font-size: 9px;
  position: relative;
  bottom: 2px;
  color: #2d2b3e;
  cursor: default;
}
// svg path{
//   cursor: default;
// }
.usepointerpath{
  cursor: pointer;
}
.tdaction{
  cursor: pointer;
}
// .flow_box_Responcive:first-child{
//   padding-left: 0;
// }
.head_rigt_text{
  font-size: 13px;
  font-weight: var(--regular);
  text-align: right;
  margin: 0 0 0 auto;
  cursor: default;
}

.issue-summary-label {
  display: inline-block;
  margin-right: 5px;
}
.thirdflowSummaryTitle {
  font-Weight: bold !important;
  white-space: nowrap !important;
  color:#202529 !important;
}
.thirdflowSummaryTitle td td {
  font-size: 18px !important;
  color: #102250 !important;
}
.thirdflowSummaryhead {
  padding: 13px 40px 0 !important;
}
.flowmetricsDD .css-1nmdiq5-menu {
  z-index: 999;
}
.actionPaddingVM{
  padding: 10px 0px 0px 0px;
}
.cancelPaddingVm{
  margin: 9px 0px 0px 0px;
}
.actionDescError{
  display: table;
}
.cloudBudgetPadding .css-qyzydv-control{
  padding:0px 0px 0px 0px;
}
.no-transform {
  text-transform: none !important;
}
.serverTdSl{
  width: 0px;
}

.riskTable th:nth-child(1) {
  width: 25% !important;
}

.riskTable th:nth-child(2),
.riskTable th:nth-child(3){
  width: 15% !important;

}
.riskTable th:nth-child(4) {
  width: 15% !important;
}

.riskTable th:nth-child(5) {
  width: 30% !important;
}
.riskTable th span {
  display: inline-block; /* Ensures the span is treated as a block element */
}
.schedule-tool{
  font-size: 18px !important;
  border-bottom: 0px !important;
}
.export-spin .spinner-border{
  --bs-spinner-width: 1.5rem !important;
  --bs-spinner-height: 1.5rem !important;
}
.export-div{
  margin-right: 20px !important;
  float: inline-end;
  margin-top: 2px;
}
.exportbtn{
  background-color: var(--primary) !important;
  border: 1px solid var(--primary);
  font-size: 13px;
  padding: 8px;
}
.exportbtn:hover{
  background-color: var(--primary-light) !important;
  color: var(--primary);
}
.ComplianceFilterStyle {
  border: 1px solid var(--primary);
  font-size: 13px;
  height: 36px !important;
  display: inline-block;
  background-color: var(--primary) !important;
  color: var(--primary-light) !important;
  border-radius: 6px;
  padding:8px 16px;
  text-transform: capitalize;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
  margin-left: 17px;
}
.ComplianceFilterStyle:hover{
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}
.ComplianceFilterStyle:disabled{
  background-color: var(--primary) !important;
  color: var(--primary-light) !important;
  border: none;
  font-size: 13px;
}

#legend-container {
  background-color: #fff;
    /* left: 71px; */
    padding: 15px 0px 41px;
    position: fixed;
    /* top: 41px; */
    z-index: 1000;
}

.vultreewidth{
  width: 100%;
}
.padForFilter{
  padding: 0px;
}
.marForFilter{
  margin: 0px;
}
.filterButtonAlignment{
  display: flex;
  justify-content: end;
}
@media only screen and (max-width: 990px) {
  .vultreewidth{
    width: 1110px;
  }
  .graphScrollTreeMap{
    height: 450px;
    overflow: auto;
  }

}
@media only screen and (max-width: 1161px) {
  .marForFilter{
    margin-top: 4px;
  }
}

@media only screen and (max-width: 1199px) {
  .filterButtonAlignment{
    display: flex;
    justify-content: start!important;
    margin: -16px 0px 7px 0px;
  }
}
.tablecontrolid2{
  margin-left: -1%;
 text-wrap: nowrap;
}
.displayhead{
  display: flex;
}
.rulewidth{
  width:8%;
  margin-left: 37px;
  text-wrap: nowrap;
}



@media (max-width: 991px){
.actright {
    flex-wrap: wrap;
}
.actright .filterSelect{
margin: 4px 0px 0px 0px ;
}
.actright .ComplianceFilterStyle, .filtercancelBtnStyle{
margin: 5px 0px 0px 0px ;
}
}
.onboardadd{
  padding: 7px 20px;
}
.observabilityFilter .control{
  flex-wrap: unset
}
.productivityMetricsDrilldownStyle{
  overflow-y: auto;
  overflow-x: hidden;
}
.Buildspin_overlay{
  padding: 185px 27px  !important;
}
.TopAssigneespin_overlay{
  padding: 56px 20px 108px 20px !important;
}

.deployoverflow-scroll{
    overflow-y: auto;
    height: 694px;
}
/* width */
.deployoverflow-scroll::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.deployoverflow-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.deployoverflow-scroll::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 15px;
}

.deplymentEnvScroll {
  padding: 0px 5px 16px 5px !important;
}
.tableTextWrap {
  text-wrap: balance !important;
}

.obsTableDate {
  color: var(--primary);
  font-size: var(--font15);
  font-weight: var(--bold);
  margin-bottom: 30px;
}

.topContributorChart .echarts-for-react {
  height: 370px !important;
}


.popUp-Table table tr.thead{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;

}
.popUp-Table table tr{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;

}
.popUp-Table table tr th{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;

}
.popUp-Table table tr td{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;

}
.drilldownChartTotalCount{
  font-size: 18px !important;
  color: #042350;
  font-weight: bold;
}
.drilldownChartTotalCount{
  font-size: 16px !important;
  color: #042350;
  font-weight: bold;
}
.productivityTotal{
  padding: 52px;
}
.drilldownTitleBox{
  padding: 10px 177px 3px 177px !important;}

.fiveflowboxcustom-col-xl-3 { //for 5 flow boxes custom style 
    flex: 0 0 auto;
    width: 20%;
   }

   .filterOptsStyle {
   // margin-bottom: 30px;
     h3 {
       font-weight: bold;
     }
   }

   .canvasFilters {
     margin-top: 15px;
     p {
       font-weight: bold;
       margin-bottom: 3px;
       font-size: 15px;
     }

   }
   .alignRight {
     text-align: right;
     // button {
     //   margin-right: 5px;
     // }
   }
   .VMCanvasFilter {
     .custom_scroll {
       height: 72vh;
       overflow-y: auto;
     }
     .filterOptsStyle {
       // margin-bottom: 30px;
       h3 {
         font-weight: bold;
       }
     }
   }

   .clearBtnStyle {
     background-color: transparent !important;
     border: 1px solid var(--primary);
     color: var(--primary) !important;
     &:hover {
       background-color: var(--primary) !important;
       color: #fff !important;
     }
     &:disabled {
       border-color: grey;
       color: grey;
     }
   }

.TableNumValuealign div {
  text-align: center;
}

.CloudBudgetNew{
  color: #ffffff !important;
  border: 1px solid var(--primary) !important;
  font-size: 16px !important;
  padding: 6px 9px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background-color: #042350 !important;
  transition: all 0.4s linear !important;
}
.CloudBudgetNew:hover {
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}
.CloudBudgetNewPosition {
    float: right !important;
}
.AskAI:hover {
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}
.business-table .row-class{
  margin-left: -6px  !important;
  margin-right: -6px !important;
}
.business-table .detailsFilterStyle {
  margin-left: -10px !important;
}
.user-csv{
  margin-bottom: -10px !important;
}
.device-licensce{
  margin-bottom: -15px !important;
}
.customScrollBorderRadius{
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.backend-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 13px;
  margin-bottom: 30px;
}
.validation-messages {
  text-align: center;
  margin-bottom: 20px;
}

.validation-message {
  background-color: red;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}
.Addnewalign {
  padding: 6px 20px !important;
}
.Addnewalign:hover {
  --bs-btn-active-bg: #ffffff !important;
}
.filtercloseicon {
  font-size: 23px   !important;
  font-weight: 300  !important;
}
.breakclass{
  word-break: break-all !important;
}
.breakclass{
  word-break: break-all !important;
}
.remove-button-business{
  font-size: 16px;
}
.toolsbtnwrap{
  margin: 0px !important;
}
.scrollCldAccountAdd {
  overflow-y: auto;
  max-height: 104vh;
}
.table-responsiveImport {
  overflow-x: auto;
  overflow-y: auto;
}
.ImportStatussticky {
  background-color: #fff;
  box-shadow: 0 2px 2px -1px #0006;
  position: sticky;
  top: -1px; /* Adjust this value based on your layout */
  background-color: white; /* Change to your desired background color */
  z-index: 100; /* Ensure it stays above other content */
}
.ImportStatusThead th{
  position: relative !important;
  top: -1px !important;
  border-bottom: 1px solid #ddd;
}
.ImportStatusThead2 th{
  position: relative !important;
  top: -2px !important;
}
.TestConnection_height{
  max-height: 250px !important;
}
.testconnectionBody {
  margin-top: 15px;
}
.testconnectionh4{
  border-bottom: 0px !important;
}
.vertAlign{
  vertical-align: top !important;
}
#versiontable{
  width: 100%;
  border-collapse: collapse;
  border-color:inherit;
}
#versiontable td,
#versiontable th{
text-align: center;
border: 1px solid #ddd;
padding: 8px;
}
.custom-select div.option.focused {
  background-color: rgba(177, 188, 147, 0.56);
}
.custom-Unselect div.option.focused {
  background-color: rgba(177, 188, 147, 0.56) !important ;
}
.tabInde .dropdown-container{
  visibility: visible;
            /* shows sub-menu */
            opacity: 1;
            z-index: 1;
            transform: translateY(0%);
            transition-delay: 0s, 0s, 0.3s;
}
input:focus,textarea:focus{
  outline: 0;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}
.btn-close:focus {
  box-shadow: none;
}
.dropdown.bootstrap-select input{
  box-shadow: none!important;
} 
.menu-container input:focus,.menu-container button:focus{
  box-shadow: none!important;
} 
.nav.small a[tabIndex="0"]:focus{
  // border: 2px solid white;
  border-left: none;
  border-right: none;
}
.tabInde .dropdown-container a[tabIndex="0"]:focus{
  border: 2px solid black;
}

.tabClickStyle{
  box-shadow :0 0 0 0.2rem rgba(49, 132, 253, 0.28)!important;
  outline :0!important;
}

.mouseClickStyle{
  box-shadow :0 0 0 0.2rem rgb(49 132 253 / 0%)!important;
  outline :0!important;
}

.buttonComponent{
  min-width: 90px;
  font-size: var(--button-size)!important;
  height: 40px;
  border: none;
}

.cisoReject {
    background-color: #dc3545!important;
    border: none!important;
}
.cisoAccept {
    margin-left: 5px;
    background-color: #198754!important;
    border: none!important;
}

.usertags {
  border: 1px solid #18347c;
}

.licenseinput .dropdown-item{
  font-family: "Montserrat", sans-serif!important;
  font-size: var(--font13);
  font-weight: var(--regular);
  font-style: normal;
}
.licenseinput .addOSList .dropdown-menu {
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
}
.extraclass{
  position: absolute;
  right: 30px;
  width: 100px;
  height: 35px;
}

.AccessDeniedalign {
  padding: 29px 0px;
}
.checkboxSMTP {
  display: flex;
  margin-bottom: 22px;
}
.labelcheckBox{
  margin: 0 20px 8px 0;
}
.AccountcreatedInput{
  margin-bottom: 13px;
  margin-left: 10px;
}
.AccountcreatedInput input{
  padding: 8px 7px 8px 7px !important;
}
.CalculationTitle{
  display: flex;
 }
.CalculationConstValue{
  margin-top: 10px !important;
}
.Conversionpopup{
  margin-top: 25px !important;
}
.CalculationUSD{
  margin-right: 16px;
   display: flex;
  justify-content: flex-end;
}

.CalculationSpace{
  margin-bottom: 2px !important;
  margin-top: 10px !important;
}
.CalculationPercentage{
  margin-bottom: 30px !important;
}
.Calculationinput{
  margin: 11px 2px 2px 2px !important;
  font-weight: 500;
}
.CurrecyInfoTable{
  width: 211px !important;
}
.disabled-icon {
  font-size: 20px;
  color: #04235080;
}
.edit-icon {
  font-size: 20px;
  color: var(--primary);
}
.delete-icon {
  font-size: 22px;
  color: var(--primary);
}
.Notificationpage {
  display: flex;
  justify-content: space-between;
  .custom_scroll {
    height: 72vh;
  }
  .filterOptsStyle {
    // margin-bottom: 30px;
    h3 {
      font-weight: bold;
    }
  }
}
.Notificationdiv{
  width: 28% !important;
  padding: 30px 17px 30px 17px !important;
  overflow-y: auto;

}
.Notificationdscroll{
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;

}
.NotificationTitle{
  margin-top: 5px;
  font-weight: bold;
  color: var(--primary) !important;
  margin-bottom: 42px;
  display: flex;
}
.NotificationBoxes{
  width: 100%;
  color: #000;
  padding: 8px 9px 5px 9px;
  border-radius: 6px;
   background: #e0e0e0;
  box-shadow: inset 35px 35px 31px #d0d0d0,
            inset -35px -35px 31px #f0f0f0;
  background-color: rgb(217, 217, 217) !important;
}
.NotificationSubject{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.NotificationSubject span h4{
  color: var(--primary) !important;
  font-weight: 600;
  margin-bottom: 10px;
  cursor:pointer;
}
.Notificationicons svg{
  font-size: 17px;
}
.NotificationButtonview{
  font-size: 12px;
  min-width: 61px;
  /* font-size: var(--button-size); */
  color: var(--primary) !important;
  color: #FFFFFF !important;
  border:  var(--primary) !important;
  margin-left: 0px;
  height: 31px;
  margin-bottom: 6px;
  margin-right: 3px;
}
.NotificationAlignment{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  cursor: pointer;
}
.Notificationcollapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3em; /* Adjust as needed */
  cursor: pointer;
}

.Notificationexpanded {
  overflow: visible;
  max-height: none;
  cursor: pointer;
}

.NotificationButtoncancel{
  font-size: 12px;
  min-width: 61px;
  /* font-size: var(--button-size); */
  background-color: #606060 !important;
  color: #FFFFFF !important;
  border: 1px solid #606060 !important;
  margin-left: 0px;
  height: 31px;
  margin-bottom: 6px;
  margin-right: 3px;
}
.NotificationButtoncancel:hover {
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}
.NotificationButtonview:hover {
  color: var(--button-bg-color) !important;
  background-color: var(--dark-theme-selected-opt) !important;
}
.NotificationTime  {
  text-align: end;
  font-size: 10px !important;
}
.NotopenedNF {
  border: 2px solid  var(--button-bg-color)  !important;
}
.NotificationClose {
  justify-content: end;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 30px;
}
.yAxispositioningLinechart{
  rotate: 270deg;
  position: absolute;
  margin: 143px 0px 0px -40px;
}
.yAxispositioningBarchart{
  rotate: 270deg;
  position: absolute;
  margin: 132px 0px 0px -60px;
}
.yAxispositioningLinechartMultiple{
    rotate: 270deg;
    position: absolute;
    margin: 141px 0px 0px -28px;
}
.yAxispositioningLinedashedchart{
  rotate: 270deg;
  position: absolute;
  margin: 182px 0px 0px -23px;
}
.yAxispositioningBarchartMultiple{
  rotate: 270deg;
  position: absolute;
  margin: 132px 0px 0px -30px;
}
.disabled-link[aria-disabled="true"]{
  pointer-events: none;
  opacity: 0.3;
  text-decoration: none;
  cursor: default;
}
.ExtractedRoleClass{
  font-weight: bold;
  font-size: 17px;
}

.SelectFYCCI div .custom-select{
  width: 146px !important;
}
.SelectFYFilter    {
  justify-content: end;
  display: flex;
}
.FYFilter    {
  width: 150px !important;
}
.GroupFilter    {
  width: 171px !important;
}
.GroupFilter div  {
  margin: 0px 5px 0px 0px !important;
}
.SelectCustomFilter    {
  display: flex;
  justify-content: space-between;
}
.CustomBtnStyle {
  min-width: 90px;
  font-size: var(--button-size);
  background-color: #ffffff !important;
  color: #0b0b0b !important;
  border: 1px solid #606060 !important;
  margin-left: 10px;
  height: 40px;
  margin-bottom: 20px;
}
.CustomBtnStyle:hover {
  background-color: #FFFFFF !important;
  color: #606060 !important;
  border: 1px solid #606060;
}
.CustomBtnStyleSelect{
  background-color: var(--primary) !important;
  color: #FFFFFF !important;
}
.activeFycolor .select-input{
      background-color: var(--primary) !important;
      color: #FFFFFF !important;
    }
    .activeFycolor .select-value{
      background-color: var(--primary) !important;
      color: #FFFFFF !important;
    }
.roleAddBtn {
  margin-top: 6px !important;
}
.roleAddBtn:disabled{
  background-color: var(--primary) !important;
  color: var(--primary-light) !important;
  border: none;
  font-size: 14px;
}
.big-plus {
  font-size: 1.5em; /* Adjust the size as needed */
  margin-left: 5px; /* Add some space between "Add Role" and the plus sign */
}

.m-t-10{
  margin: 10px 0px 0px 0px !important;
}
.businesschartTitle{
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--primary) !important;
    padding-bottom: 2px !important;
    border-bottom: 1px solid #fff !important;
 }
.businesschartTitle span{
  font-weight: 400;
  color: black;
}
.businessinfoRole{
  margin: 0px 4px 13px 4px !important;
}
.businessinfochartbtn{
  display: flex;
  justify-content: end;
}
.businessinfoSpinner{
  z-index: 998;
  overflow: hidden;
  height: 100%;
  padding: 105px 20px;
  position: relative;
  display: grid;
  justify-items: center;
}
.displayReviewersError{
  border: 1px solid #ff0000 !important;
}
.displayReviewersErrorTest{
    white-space: nowrap;
    color: #d70000;
    border-radius: 4px;
    font-size: 10px;
    margin-left: auto;
    margin-right: 1px;
    margin-bottom: 1px;
}
.BusinessInfoNodataAvailable {
    color: var(--primary) !important;
    text-align: left !important;
    position: relative !important;
    text-align: center !important;
    padding: 131px !important;
    height: 50px !important;
    top: 0px !important;
    bottom: 10px !important;
    font-size: 13px !important;
    font-weight: bold !important;
   }
.role-cell {
  white-space: pre-wrap; /* This ensures that text wraps to the next line */
  word-break: break-word; /* Breaks long words if necessary */
}
.addgrouplabel {
  display: flex;
  justify-content: space-between;
}
.addGroupError{
  white-space: nowrap;
  color: #d70000;
  border-radius: 4px;
  font-size: 10px;
  margin-left: auto;
  margin-right: 1px;
  margin-bottom: 1px;
}
.smtp_toggle-control {
  position: relative;
  // padding-left: 100px;
  margin-top: -35px;
  margin-right: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}
.smtp_toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.smtp_toggle-control input:checked~.control {
  background-color: #b1bc93;
}

.smtp_toggle-control input:checked~.control:after {
  background-color: #a8cf3e;
}

.smtp_toggle-control input:checked~.control:after {
  left: 15px;
}

.smtp_toggle-control .control {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  height: 11px;
  width: 30px;
  border-radius: 25px;
  background-color: #939393;
  transition: background-color 0.15s ease-in;
  margin: auto;
}

.smtp_toggle-control .control:after {
  content: "";
  position: absolute;
  left: -3px;
  top: -3px;
  width: 16px;
  height: 16px;
  border-radius: 25px;
  background: #fff;
  transition: left 0.15s ease-in;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
}
.togglealign{
  display: flex;
  justify-content: end;
}
.toggleLabel {
  margin-top: -38px;
  margin-right: 15px;
  font-size: var(--font13);
  color: var(--dark);
}
.GropSelectionBI {
  margin: 11px 7px 11px 0px;
}
.resourceTypePopup {
  max-height: 400px;
  overflow-x: auto;
}
.resourcechartlegend {
  overflow-x: auto !important;
  display: flex !important;
  white-space: nowrap !important;
}
.resourcechartscroll {
  width: 30px !important;
  height: 29px !important;
}
.resourcescrolldirection {
  display: flex;
  margin: 0 18px 9px auto;;
  align-items: center;
}
.resourcescrollarrow  {
  margin: 11px 3px 3px 3px;
}
.overlay-message {
  width: 100%;
  font-weight: bold !important;
  text-align: center;
  background-color: white;
  padding: 35px;
  font-size: 15px !important;
  border-radius: 6px;
}

//nav bar

.firstChildNav-heading {
  font-weight: bold;
  text-align: left !important;
  flex-direction: row;
  display: flex;
  // padding-left: 5px;
  border-bottom: 1px solid #18347c;
  padding-bottom: 11px;
  background-color: #ffffff;
  padding-top: 10px;
  border-top-right-radius: 10px;
  margin: 0 14px;
  white-space: nowrap ;
}
.firstChildNav-heading.noborder{
  border-bottom: none;
}
.firstChildNav-heading-hr {
  margin: 0px 0px;
  border: none;
  border-bottom: 1px solid #515151;
  width: 100%;
}
.dropdown-container_child a:hover{
  font-weight: bold;
}
.dropdown-container a:hover  {
  font-weight: bold;
}
.totalspent {
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}
.currentUsage{
  font-size: 17px;
  font-weight: bold;
}
.ytd1stTeaxt{
  border: 3px solid red;
  border-radius: 33px;
  margin: 0px 0px 14px 0px;;
}
.ytd11stTeaxt{
  font-size: large;
  font-weight: bold;
}
.ytd2stTeaxt{
  border: 3px solid #47aa56;
  border-radius: 33px;
  margin: 0;
}
.tableInventoryHighlight {
  color: blue !important;
}
.untaggedEntryHighlight{
  color: #9e7d26 !important;
  font-weight: 550;
}
@media screen and (max-width: 1270px) {
  .ytd1stTeaxt .echarts-for-react {
    height: 130px !important;
  }
  .ytd2stTeaxt .echarts-for-react {
    height: 130px !important;
  }
  .ytd1stTeaxt .echarts-for-react canvas{
    height: 130px !important;
  }
  .ytd2stTeaxt .echarts-for-react canvas{
    height: 130px !important;
  }
}

// .ytd2stH4{
//   font-size: 12px;
// }

.cloudInsighsChart .echarts-for-react {
  height: 400px !important;
}

.serverTable tfoot th {
  background-color: rgb(220, 223, 221);
}
.serverTable tfoot tr {
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
}
.serverTable thead tr:last-child {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.summaryHeading{
  font-size: larger;
}
.summaryHeadingSpent{
  font-size: 20px;
}

//Drag&Drop
.DnDSearchCover{
  height: 45px;
}
.mainDragOuter{
  border: 3px solid #131c47;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
.mainDragOuter h4{
  border: none;
  padding: 0;
  margin: 0;
}
.mainDragBox {
  overflow-y: auto;
  max-height: 180px;
  min-height: 180px;
  width: 100%;
}
.mainDnDBox{
  overflow-y: auto;
  max-height: 140px;
  min-height: 140px;
  width: 100%;
}
.mainDropBox {
  overflow-y: auto;
  max-height: 180px;
  min-height: 180px;
  width: 100%;
}
.mainDropOuter{
  padding: 10px;
  border: 3px solid #0269CC;
  border-radius: 8px;
}
.mainDropOuter h4{
  border: none;
  padding: 0;
  margin: 0;
}
.mainDropBox .dragableTags{
  margin: 5px;
}
.dragableTags {
  color: #ffff;
  margin: 5px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 9px;
  display: inline-block;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.dragableTags.tagged {
  background: #131C47;
}
.dragableTags.untagged {
  background: #9E7D26;
}
.dragableTags .closebtn{
  margin-left: 10px;
    color: white;
    background: none;
    border: none;
    font-weight: bold;
}
.dragableTags.dropedTags{
  background-color: #0269CC;
}
.dragableTags.is-dragging {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}
.mainDropBox.is-dragging-over {
  background-color: #f0f0f0;
  transition: background-color 0.2s ease;
}
.tagTypeBtn {
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 5px 15px;
  border: none;
  color: #ffffff;
  background-color: #131c474d;
}
.tagTypeBtn.active{
  background-color: #131C47;
}
.untaggedBtn.active{
  background-color: #9E7D26;
}
.untaggedBtn{
  background-color: #9e7d2666;
}
.SFGMStyle{
  padding: 3px 15px;
  border: 1px solid #0469c9;
  border-radius: 40px;
  background-color: #0469c9;
  color: white;
  margin: 2px 2px;
  display: inline-block;
}
.includeSoftwareBox p{
  text-align: left;
  font-size: 12px;
}
.includeSoftwareBoxSpan{
max-height:150px;
overflow-x: auto;
display: block;
}
.paddingadj{
  padding-right: 18px;
}
.red-asterisk {
  color: red;
}

.NodataAvailableSoftware{
  color: var(--primary) !important;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.includeSoftwareBoxSpan::-webkit-scrollbar {
  width: 3px;
  height: 2px;
}
.includeSoftwareBoxSpan::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.includeSoftwareBoxSpan::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 10px;
}
.includeSoftwareBoxSpan::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ToolsTooltip .tooltip-inner {
  background-color: #fff;
  border: 1px solid #131C47;
  color: #131C47;
  font-weight: bold;
}
.ToolsTooltip .tooltip-arrow {
  color: #131C47;
}
.ToolsTooltip.tooltip.show{
  opacity: 1 !important;
}
.softwareincluedsEntitle{
  font-size: 13px !important;
  text-align: left !important;

}
.noentitleinput{
  color: red;
  margin-bottom: 5px;
}
.disabledEntitledSG{
  color: red;
  margin-bottom: 5px;
  margin-top: 8px;
  font-weight: 600;
}
.cloudCostWidth img{
  width: 30px !important;
}
.bottom-scroll-home{
  white-space: nowrap;
}

.licenseValidate_attest {
  display: flex !important;
    justify-content: center !important;
}

.licenseValidate_attest .btnStyle{
  margin: 1px 2px 2px 2px  !important;
  margin-bottom: 0px !important;
  height: 33px !important;
  border-radius: 10px !important;
}
.LicenseEntitledTooltip .tooltip-inner {
  background-color: #fff;
  border: 1px solid var(--orange);
  color: var(--orange);
  font-weight: bold;
  word-break: unset;
  white-space: nowrap;
  max-width: none;
  // text-wrap: nowrap;
  padding: 8px 3px;
  width: fit-content;
}
.LicenseEntitledTooltip .tooltip-arrow {
  color: #131C47;
}
.LicenseEntitledTooltip.tooltip.show{
  opacity: 1 !important;
}
.licenseManagementCustom .customTab{
  padding: 0px 0px !important;
}
.label-value-row {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  margin-left: 7px;
  width: 100%;
}

.label-text {
  flex: 0 0 50%;
  text-align: left;
  font-weight: 500;
  word-break: unset;
}
.label-text_attest {
  flex: 0 0 71%;
  text-align: left;
  font-weight: 500;
  word-break: unset;
}

.colon {
  margin: 0 8px;
  text-align: center;
}

.value-text {
  flex: 1;
  text-align: left;
}
.license-modal_custom{
  min-width: 31vw !important;
  max-width: 90vw !important;
  width: fit-content !important;
}
.inputClassNameConsumed {
  padding: 0px 5px !important;
}
.SoftwaregroupDeletedis {
  opacity: 0.5 !important;
}
.LicenseSummaryTooltip .tooltip-inner {
  background-color: #fff;
  border: 1px solid var(--black);
  color: var(--black);
  font-weight: 500;
  word-break: unset;
  text-wrap: nowrap;
  padding: 8px 12px;
  width: fit-content;
}
.LicenseSummaryTooltip .tooltip-arrow {
  color: #000;
}
.LicenseSummaryTooltipText{
  text-align: left;
}
.LicenseSummaryTooltipTextcolor{
  color: var(--black);
}
.LicenseSummaryTooltip.tooltip.show{
  opacity: 1 !important;
}
.inputvalidateLicense {
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top:0;
  right: 1%;
  color: #d98e00;
}
.inputvalidateLicense.error{
  top: 10%;
}
.relativePosition{
  position: relative;
}
.ActiveUserDrillScroll{
  overflow-y: auto;
  overflow-x: hidden;
}
.nowrapImpo {
  white-space: nowrap !important;
}
.cosumed_Validate{
  font-weight: 600;
  width: 550px;
  color: red;
  margin-bottom: 5px;
}

.font-weight-800{
  font-weight: 800;
  margin-bottom: 10px;
}
.font-weight-400{
  font-weight: 400;
}
.fileAttachColor{
  color: blue !important;
}
.attachment-item {
  padding: 8px;
  background-color: #EEF1F5;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.attachment-details {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.attachment-list {
  max-height: 188px;
  overflow-y: auto;
  padding: 4px;
}
.attachment-size {
  color: #272727;
  font-size: 0.85em;
}
.m-auto {
  margin: auto;
}
.truncate {
  // white-space: nowrap;       /* Prevent text from wrapping to the next line */
  overflow: hidden;          /* Hide any text that overflows the container */
  text-overflow: ellipsis;   /* Add an ellipsis (...) at the end of the overflowed text */
}

@media screen and (max-width: 1280px) {
  .truncate {
    width: 207px;
  }
}

@media screen and (max-width: 1422px) {
  .truncate {
    width: 250px;
  }
}
.tierErrorAlign{
  margin-top: -16px;
}
.tiererror {
  white-space: nowrap;
  color: #9e7d26;
  font-weight: 700;
  margin: -10px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: auto;
  margin-right: 1px;
  margin-bottom: 1px;
}
.speedDonutChartTooltip{
  color: #000000bf;
  font-weight: 700;
  position: relative;
  top: -80px;
}
.speedDonutChartTooltipTooltip {
  background-color: white; 
  color: black;
  font-size: 14px; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
}
.speedDonutChartTooltipTooltip .tooltip-inner{
  width: 140px;
  background-color: white;
  color: black;
  border-radius: 8px;
}
.speedDonutChartTooltipTooltip .tooltip-arrow::before {
  position: absolute;
  left: -50% !important;
  top: 1% !important;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.speedDonutChartTooltipTooltip .arrow::before {
  border-top-color: #333;
}
.speedDonutChartTooltipDescription {
  background-color: white; 
  color: black;
  font-size: 14px; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
}
.speedDonutChartTooltipDescription .tooltip-inner{
  width: 140px;
  background-color: white;
  color: black;
  border-radius: 8px;
}
.speedDonutChartTooltipDescription .tooltip-arrow::before {
  position: absolute;
  left: -50% !important;
  top: 1% !important;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.speedDonutChartTooltipDescription .arrow::before {
  border-top-color: #333;
}
.truncated-description {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; 

}
.displayUsersLM {
  width: 100%;
  border-radius: 5px;
  height: 77px;
  background-color: #F2F4F2;
  overflow: auto;
}
.displayUsersLMSelect {
  border-radius: 4px 4px 4px 4px;
}
.displayUsersLMMail {
  height: 100px;
  max-height: 200px;
  color: #3e3d3d !important;
}


.Custom_tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.Custom_tooltip .Custom_tooltipText {
  visibility: hidden;
  width: 120px;
  //background-color: black;
  // color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: fixed;
  height: fit-content;
  z-index: 99999;
  margin-left: -60px;
  font-size: 10px;
}
.Custom_tooltip .Custom_tooltipText.untagged{
  background-color: #fff;
  border: 1px solid #9E7D26;
  color: #9E7D26;
  font-weight: bold;
}
.Custom_tooltip .Custom_tooltipText.tagged{
  background-color: #fff;
  border: 1px solid #131C47;
  color: #131C47;
  font-weight: bold;
}
.Custom_tooltip .Custom_tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
.Custom_tooltip .Custom_tooltipText.untagged::after{
  border-color: #9E7D26 transparent transparent transparent;
}
.Custom_tooltip .Custom_tooltipText.tagged::after{
  border-color: #131C47 transparent transparent transparent;
}
.Custom_tooltipText.show{
  display: block;
}
.Custom_tooltipText.hide{
  display: none;
}
.Custom_tooltip:hover .Custom_tooltipText {
  visibility: visible;
}

// .dragableTags[title-new]:hover:before {
//     content: attr(title-new);
//     position: fixed;
//     display: block;
//     z-index: 10;
//     font-size: 12px;
//     // padding: 5px 10px;

//     font-weight: 500;
//     border-radius: 4px;
//     background: #ece2ff;
//     border: 1px solid var(--primary);
//     color: var(--primary);
//     font-family: sans-serif;
//     max-width: 100px;
//     left: var(--tipLeft);
//     width: 100px;
//     top: var(--tipTop);
//     text-decoration: none;
//     text-align: center;
//     // transition: all 0.3s linear;
// }
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-tooltip .custom-tooltip-text {
  max-width: none;
  visibility: hidden;
  border: 1px solid var(--orange);
  font-weight: bold;
  color: var(--orange);
  background-color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: -30px;
  margin-left: -60px;
}
.custom-tooltip .custom-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.custom-tooltip:hover .custom-tooltip-text {
  visibility: visible;
}
.costoverViewSticky{
  left: 225px !important;
}
.Esxemptymsg{
  position: relative !important;
  top: 5px !important;
  text-align: left !important;
  color: #d70000 !important;
  font-size: 14px !important;
}
.TableLMTextRemove{
  border-left: none !important ;
  background-color: #ffffff;
  border-radius: 0px;
  border-radius: 0px 0px 0px 0px !important;
  border-right: none !important ;
  border-left: none !important ;
}
.TableLMTextRemove:hover {
  background-color: var(--bs-body-bg);
}
.TableLMTextRemoveInput {
  border-right: none !important ;
}
.SearchInput {
  margin-left: 7px !important;
  padding: 8px 23px !important;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 15px !important;
}
.SearchInput_scroll {
  overflow-x: hidden !important;
}
.SearchInput {
  margin-left: 7px !important;
  padding: 8px 23px !important;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 15px !important;

}
.SearchInput_scroll {
  overflow-x: hidden !important;
  width: 310px !important;
}
.SearchInput_scrollX {
  top: 39px !important;
  width: 310px;
}

.dropdownitem_serachInput{
  margin-left: 6px;
}

.isOthersSelected .mainDnDBox {
  overflow-y: auto;
  max-height: 210px !important;
  min-height: 210px !important;
  width: 100%;
}