.observabilityFilter {
    width: 16%;
    // display: inline-block;
    margin: 0px 0px 15px 0px;
        .form-control {
         height: 38px;
         border-radius: 4px;
        }
}
.hiddenFilterOpt {
    visibility: hidden;
    display: inline;
}
.obsFilterBtn {
    vertical-align: baseline;
}
.obsDateInput {
    border: 1px solid var(--primary);
    height: 39px;
}
//People Metrics drilldown
.OptionsStyle {
    border: 1px solid;
    // margin: 5px;
    margin-top: 20px;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}
.OptionsStyle:hover {

}
.peopleDrilldownTable {
    border: 1px solid #e5e5e5;
    margin-top: 20px;
    padding: 5px;
    border-radius: 5px;
}
.peopleMetricsDropdownClass {
    width: 200px;
    margin-top: 20px;
  }
.TopAssignee {
    margin-top: -80px;
}