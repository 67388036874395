.dropdown-container_child.ops {
    top: 0%;
    left:100%;
}
// .dropdown-container_child.admin {
//     top: 50%;
// }
.dropdown-container_child.Compliance{
   top:25%
}
.dropdown-container_child.efficiency {
    top: 50%;
}