@charset "UTF-8";
// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat');// url(../fonts/d-din/D-DIN.woff) format('woff');
  font-weight: normal;
}

// @font-face {
//   font-family: 'D-DIN-Bold';
//   src: local('D-DIN-Bold'), url(../fonts/d-din/D-DIN-Bold.woff) format('woff');
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'D-DIN-Italic';
//   src: local('D-DIN-Italic'), url(../fonts/d-din/D-DIN-Italic.woff) format('woff');
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'D-DINCondensed';
//   src: local('D-DINCondensed'), url(../fonts/d-din/D-DINCondensed.woff) format('woff');
//   font-weight: 400;
// }
// @font-face {
//   font-family: 'D-DINCondensed-Bold';
//   src: local('D-DINCondensed-Bold'), url(../fonts/d-din/D-DINCondensed-Bold.woff) format('woff');
//   font-weight: 400;
// }


* {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #102250;
  --secondary: #A6CE38;
  --white: #fff;
  --black: #000;
  --text: #2d2b3e;
  --dark: #030303;
  --lightbg: #F2F4F2;
  --border: #D6D9DC;

  --primary-light: #fff;
  --secondary-dark: #102250;
  --secondary-light: #102250;
  --success: #7DBB00;

  --gray1: #919191;
  --gray2: #9D9D9D;
  --gray3: #808080;
  --gray4: #D5D5D5;
  --gray5: #EAEAEA;
  --gray6: #F0EFF4;
  --gray7:#606060;
  --gray8: #e8e8e8;




  --blue: #147AD6;
  --blue-light: #79D2DE;
  --pink: #ff7e80;
  --purple: #be88e7;
  --yellow: #E9D96D;
  --orange: #FCBB4B;


  --h1: 72px;

  --font60: 60px;
  --font25: 25px;
  --font24: 24px;
  --font22: 22px;
  --font20: 20px;
  --font18: 18px;
  --font17: 17px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;

  --thin: 100;
  --extralight: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
  --black900: 900;

  --body-font: 'Montserrat', sans-serif;
}

/*------------ Rule css -----------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: var(--font60);
   
}

h3 {
  font-size: var(--font20);
}

h4 {
  font-size: var(--font14);
}
.fs-32{
  font-size: 32px;
}
:focus {
  // outline: -webkit-focus-ring-color auto 0;
}

a,
a:hover,
a:focus,
a:visited {
  // outline: 0;
  text-decoration: none;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .1s linear;
}

a {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  outline: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  list-style-type: none;
  text-decoration: none;
}

// :focus {
//   outline: 0 !important;
//   box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
// }

::selection {
  background-color: var(--primary);
  color: var(--white);
}
// .row{
//   margin: 0;
//   --bs-gutter-x:0;
// }
/*--------- background class --------*/
.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--primary) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-light {
  background-color: var(--lightbg) !important;
}

/*--------- elements page --------*/

.elementwrap {
  padding: 50px 15px 80px;
}

.elementwrap h2,
.elementwrap .h2 {
  margin: 25px 0;
  display: block;
}

.elementwrap h4,
.elementwrap .h4 {
  margin: 15px 0;
  display: block;
}

.elementwrap .col-md-6 {
  padding-bottom: 30px;
}

.elementwrap .col-md-4,
.elementwrap .col-md-3 {
  padding-bottom: 30px;
}
// [class*="col-"] {
//   padding: 0 5px;
// }


/*-------------------- main buttons -------------------------*/
.solid-btn {
  font-size: var(--font14);
  text-align: center;
  font-weight: var(--regular);
  display: inline-block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  text-transform: capitalize;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.solid-btn:hover {
  background-color: var(--white);
  color: var(--primary);
}

.primary-btn {
  font-size: var(--font16);
   
  font-weight: var(--regular);
  display: inline-block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  padding: 10px 18px;
  border: 1px solid var(--primary);
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.primary-btn:hover {
  background-color: var(--white);
  color: var(--primary);
}

.border-btn {
  color: var(--primary);
  border: 1px solid var(--primary);
  font-size: 12px;
   
  padding: 7px 17px;
  border-radius: 3px;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.border-btn span img {
  width: 16px;
  margin-right: 16px;
}

.border-btn:hover {
  background-color: var(--primary-light);
  color: var(--primary);
}

.default-btn {
  color: var(--primary);
  border: 1px solid var(--primary);
  font-size: 16px;
  text-transform: capitalize; 
  padding: 10px 18px;
  border-radius: 6px;
  background-color: var(--white);
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.default-btn:hover {
  background-color: var(--primary);
  color: var(--white);
}







.outline-btn {
  font-size: var(--font13);
   
  font-weight: var(--bold);
  display: inline-block;
  background-color: var(--primary-light);
  color: var(--primary-active);
  border-radius: 6px;
  padding:8px 16px;
  border: 1px solid var(--primary-active);
  text-transform: capitalize;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}

.outline-btn:hover {
  background-color: var(--primary-active);
  color: var(--primary-light);
}

.graybtn {
  background-color: #606060;
  border: 1px solid #606060;
}

// .btn-primary.greenbtn {
//   min-width: 90px;
//   height: 40px;
//   font-size: var(--button-size);
//   background-color: var(--secondary);
//   border: 1px solid var(--secondary);
//   color: #42560a;
// }

// .btn-primary.greenbtn:hover {
//   color: #42560a;
//   border: 1px solid #42560a;
//   background-color: #ffffff;
// }

.btnswrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
}

.btnswrap .solid-btn {
  margin: 0 5px;
}

/*-------------------- form elements -------------------------*/
.frmgroup {
  margin-bottom: 13px;
}

.frmgroup input {
  font-size: var(--font14);
  font-weight: var(--regular);
  color: var(--gray2);
  background-color: var(--lightbg);
  width: 100%;
  padding: 20px 24px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--primary-active);
  // border: 0;
}

.dateinput input {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 11px 30px 9px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 15px 12px;
  cursor: pointer;
}
.dateinputflowMetrics input {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 11px 30px 6px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 15px 12px;
  cursor: pointer;
}

.default-input label,
.radiogroup label {
  font-size: var(--font13);
   
  font-weight: var(--regular);
  font-style: normal;
  color: #080707;
 // margin-bottom: 6px;
}

.default-input input {
  // font-size: var(--font14);
  height: 15px;
  font-weight: var(--regular);
  font-style: normal;
  color: var(--gray3);
  // border: 1px solid var(--border);
  font-size: var(--content-size);
  padding: 19px 7px 17px 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  // padding: 15px 0px 19px 7px;
}
.default-input input:focus{
  background-color: #f2f4f2;
  border: 1px solid var(--secondary-light);
}

/*--------- radio --------*/
.form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 2px solid var(--black);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
}

.form-check-input:checked[type="radio"] {
  // background-image: url(../images/radio-2.svg);
  background-color: transparent;
  background-size: 8px 8px;
  border-color: var(--black);
}

/*--------- checkbox --------*/
.form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid var(--black);
}

.form-check-input:checked[type="checkbox"] {
  background-color: #fff;
  background-size: 10px 8px;
  border-color: var(--black);
}

/*--------- checkbox & radio label --------*/
.form-check-label {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  margin-left: 12px;
  cursor: pointer;
  margin-top: 2px;
}

/*--------- default select --------*/
.form-select {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light) !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: right 0.45rem center;
  background-size: 12px 12px;
}

.frmgroup .form-check-input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary-light);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  background-color: var(--white);
}

.frmgroup .form-check-input:checked[type="radio"] {
  background-image: url(../images/radio-2.svg);
  background-color: var(--white);
  background-size: 8px 8px;
  border-color: var(--secondary-light);
}


.radiogroup .form-check-inline {
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
}

.radiogroup .form-check-inline label {
  margin-bottom: 0;
}

/*------------------------------- bootstrap-select css --------------------------------------------------*/

/*!
 * Bootstrap-select v1.14.0-beta2 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2021 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */

@-webkit-keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

select.bs-select-hidden,
.bootstrap-select>select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle;
}

.bootstrap-select>.dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bootstrap-select>.dropdown-toggle:after {
  margin-top: -1px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active {
  color: #999;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:hover,
// .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:focus,
// .bootstrap-select>.dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5);
}

.bootstrap-select>select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}

.bootstrap-select>select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2 !important;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select select:invalid+.dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select select:valid+.dropdown-toggle {
  border-color: #28a745;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto;
}

:not(.input-group)>.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  float: none;
  z-index: auto;
}

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
  width: 100%;
}

.bootstrap-select.disabled,
.bootstrap-select>.disabled {
  cursor: not-allowed;
}

.bootstrap-select.disabled:focus,
.bootstrap-select>.disabled:focus {
  outline: none !important;
}

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.bootstrap-select .dropdown-toggle .bs-select-clear-selected {
  position: relative;
  display: block;
  margin-right: 5px;
  text-align: center;
}

.bs3.bootstrap-select .dropdown-toggle .bs-select-clear-selected {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .bs-select-clear-selected span {
  position: relative;
  top: -webkit-calc(((-1em / 1.5) + 1ex) / 2);
  top: calc(((-1em / 1.5) + 1ex) / 2);
  pointer-events: none;
}

.bs3.bootstrap-select .dropdown-toggle .bs-select-clear-selected span {
  top: auto;
}

.bootstrap-select .dropdown-toggle.bs-placeholder .bs-select-clear-selected {
  display: none;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .dropdown-menu>.inner:focus {
  outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select .dropdown-menu li {
  position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .dropdown-menu .notify.fadeOut {
  -webkit-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
  -o-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
  animation: 300ms linear 750ms forwards bs-notify-fadeOut;
}

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0';
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show>.dropdown-toggle .filter-option:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group {
  display: block;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group {
  display: block;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox+.bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}

/*# sourceMappingURL=bootstrap-select.css.map */


.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 0px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active {
  color: #000;
}

.modal .bootstrap-select>.dropdown-toggle {
  font-style: italic;
  font-weight: 300;
}

.bootstrap-select>.dropdown-toggle {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  font-style: normal;
  height: auto;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.dropdown-toggle::after {
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 12px;
  border: 0;
  width: 14px;
  height: 7px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: inherit;
}

.dropup .dropdown-toggle::after {
  border: 0;
  background-image: url(../images/down-caret.svg);
}

.bootstrap-select .dropdown-menu {
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 0 0 8px 8px;
  padding: 0;
  min-width: 95%;
}

.bootstrap-select .dropdown-menu li a.dropdown-item {
  padding: 12px 22px;
  text-indent: 0px;
  font-size: 15px;
   
  color: #000;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  margin-bottom: 2px;
}

.bootstrap-select .dropdown-menu li a.dropdown-item:hover,
.bootstrap-select .dropdown-menu li a.dropdown-item:focus {
  color: #000;
  background: var(--secondary-light);
}

.bootstrap-select .dropdown-menu li a.dropdown-item.active,
.bootstrap-select .dropdown-menu li a.dropdown-item:active {
  color: #000;
  background: var(--secondary-light);
}

.bootstrap-select .dropdown-menu li a.dropdown-item.disabled,
.bootstrap-select .dropdown-menu li a.dropdown-item:disabled {
  color: #a0a0a0 !important;
}

.bootstrap-select.multiselect .dropdown-toggle {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: var(--white);
  padding: 10px;
  width: 100%;
  font-style: normal;
  height: auto;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bootstrap-select .dropdown-menu li a.dropdown-item.selected {
  background: rgba(208, 229, 253, 0.43);
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  display: none;
}

.bootstrap-select>.dropdown-toggle:after {
  margin-top: 2px;
}

.bs-searchbox input {
  font-size: var(--font14);
   
  font-weight: var(--regular);
  color: var(--gray2);
  background-color: var(--lightbg);
  width: 100%;
  padding: 8px 15px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--secondary-light);
}

.form-control:focus {
  // color: var(--gray2);
  background-color: #fff;
  border-color: var(--secondary-light);
}

/*---------------------------------- datepicker css ------------------------------------------*/
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary);
  border-color: transparent;
  color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

/*---------------------------------- multiselect css ------------------------------------------*/

.ms-options-wrap,
.ms-options-wrap * {
  box-sizing: border-box;
}

.ms-options-wrap {
  width: 100%;
}

.ms-options-wrap .ms-options {
  display: none;
}

.ms-options-wrap.ms-active .ms-options {
  display: block;
}

.ms-options-wrap>button:focus,
.ms-options-wrap>button {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: var(--font14);
   
  font-weight: var(--regular);
  font-style: normal;
  color: var(--text);
  border: 1px solid var(--secondary-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: Red;
  padding: 10px 22px 10px 10px;
  width: 100%;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-options-wrap>button:after {
  content: ' ';
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  border: 5px solid rgba(0, 0, 0, 0);
  border-top-color: #000;
  margin-top: -3px;
}

.ms-options-wrap>button:after {
  background-image: url(../images/down-caret.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 7px;
  border: 0;
  width: 12px;
  height: 7px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.ms-options-wrap>.ms-options {
  position: absolute;
  /*left: 0;*/
  width: max-content;
  max-width: 250px;
  margin-top: 1px;
  margin-bottom: 20px;
  background: white;
  z-index: 2000;
  border: 1px solid #aaa;
  text-align: left;
}

.ms-options-wrap>.ms-options>.ms-search input {
  width: 100%;
  padding: 4px 5px;
  border: none;
  border-bottom: 1px groove;
  outline: none;
}

.ms-options-wrap>.ms-options .ms-selectall {
  display: block;
  font-size: .8em;
  text-decoration: none;
  color: var(--primary);
  font-weight: 500;
  font-style: normal;
  text-align: left;
}

.ms-options-wrap>.ms-options .ms-selectall:hover {
  text-decoration: none;
}

.ms-options-wrap>.ms-options>.ms-selectall.global {
  margin: 4px 5px;
}

.ms-options-wrap>.ms-options>ul>li.optgroup {
  padding: 5px;
  color: #a2a2a2;
}

.ms-options-wrap>.ms-options>ul>li.optgroup+li.optgroup {
  border-top: 1px solid #eaeaea;
}

.ms-options-wrap>.ms-options>ul>li.optgroup .label {
  display: block;
  padding: 5px 0 0 0;
  font-weight: 300;
  color: #A2A2A2;
  font-size: 15px;
   
}

.ms-options-wrap>.ms-options>ul label {
  position: relative;
  display: grid;
  grid-template-columns: 22px auto;
  align-items: center;
  width: 100%;
  padding: 5px 8px !important;
  margin: 1px 0;
  color: #000;
  font-size: 14px;
   
}

.ms-options-wrap>.ms-options>ul li.selected label,
.ms-options-wrap>.ms-options>ul label:hover {
  background-color: #efefef;
}



.ms-options-wrap>.ms-options>ul input[type="checkbox"] {
  margin-right: 14px;
  /* position: absolute; */
  /* left: 10px; */
  /* top: 7px; */
  width: 14px;
  height: 14px;
}

.ms-options-wrap>.ms-options {
  background: #fff;
  border: 0;
  box-shadow: 0 3px 2px rgb(0 0 0 / 16%);
  padding: 10px;
  border-radius: 4px;
}

.ms-options-wrap>.ms-options>.ms-search {
  position: relative;
}

.ms-options-wrap>.ms-options>.ms-search input {
  width: 100%;
  padding: 4px 8px 5px 18px;
  border: none;
  border: 1px solid var(--primary);
  outline: none;
  background-color: #ffffff;
  font-style: normal;
  font-size: 12px;
   
  font-weight: 400;
  height: 26px;
  color: #000;
}

.ms-options-wrap>.ms-options>.ms-search:before {
  position: absolute;
  content: "";
  left: 6px;
  top: 10px;
  // background-image: url(../images/search.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 8px;
  height: 8px;
}

.ms-options-wrap>.ms-options>ul li.selected label,
.ms-options-wrap>.ms-options>ul label:hover {
  background-color: rgb(177 188 147 / 56%);
}
// .user-email{
//   padding: 10px !important;
// }

.chartHolder svg {
  cursor: default !important;
}

path, circle, rect, line {
  cursor: pointer;
}


