.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 66%);

    .sectitle {
        font-size: 20px;
         
        color: var(--primary);
        font-weight: var(--bold);
        margin-bottom: 25px;
    }

        btn-close {
        box-sizing: content-box;
        padding: 0;
        color: var(--primary);
        background: none;
        border: 0;
        border-radius: 0;
        opacity: 1;
        font-size: 30px;
        font-weight: 300;
        position: absolute;
        right: 20px;
        top: 0px;
        z-index: 999;
        font-family: 'Barlow', sans-serif;
    }
    .btnwrap {
        border-top: 1px solid #d5d5d5;
        padding: 27px 0 0;
        margin: 30px 0 0;
    }
}

.modal-body h4 {
    text-align: left;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    width:100%;
}
.modify {
    font-size: 18px !important;
}
.modal-body p {
    font-size: 18px;
    font-weight: 400;
    color: #030303;
    text-align: center;
}

.pagination_place {
    overflow-y:auto ;
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(0px, -32px);
}
#react-select-4-input{
    height: 15px;
}
.addMemberInput{
    width: 30vh;
    .dropdown-menu{
        min-width: 20rem;
    }
    .dropdown-menu::-webkit-scrollbar {
        display: none;
      }
}
.addOSList{
    width: 30vh;
    .dropdown-menu{
        min-width: 11rem;
        overflow-y: auto;
        height: 200px;
    }
    // .dropdown-menu::-webkit-scrollbar {
    //     display: none;
    //   }
}
// .dropdown-menu::-webkit-scrollbar {
//     display: none;
//   }
.dropdown-item{
    margin-left: -6px;
}
  .dropdown-item:hover{
    color: #1e2125;
    background-color: #e9ecef;
}
.notFocus{
.form-control:focus{
    background-color: var(--lightbg); ; 
}
}